import React, { Component, useEffect, useState } from "react";
import * as Constants from "../../../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import Footer from "../../../footer/footer.component";
import Switch from "react-switch";

class CreateCpCategories extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      name: this.props.resp ? this.props.resp.data.output.name : "",
      code: this.props.resp ? this.props.resp.data.output.code : "",
      active: this.props.resp ? this.props.resp.data.output.active : false,

      sortBy: "name",
      limit: 1000,
      page: 1,
      searchTerm: "",

      search: "",
      create: 1,
    };
    this.activeHandleChange = this.activeHandleChange.bind(this);

  }

  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount()
    );
  };
  nameHandleChange = (event) => {
    this.setState({ name: event.target.value });
  };
  codeHandleChange = (event) => {
    this.setState({ code: event.target.value });
  };
  activeHandleChange(checked) {
    this.setState({ active: checked });
  }

  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      const token = localStorage.getItem("token");
      const currentURL = window.location.pathname;
      const parts = currentURL.split("/");
      let id = parts.pop();
      id = parseInt(id);
      if (this.props.resp) {
        axios
          .patch(
            Constants.api + "company/cProduct/category/" + this.props.resp.data.output.id,
            {
              "name": this.state.name,
              "code": this.state.code,
              "active": this.state.active,


            },
            {
              headers: {
                Authorization: `Bearer ${token}`, company: id, program: 0, appVersion: Constants.av,
                platform: Constants.pt
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              swal("Category Updated");
              this.setState({
                "name": "",
                "code": "",
                "active": false
              });
            }  else if (res.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          })
          .catch((err) => {
            //localStorage.clear();
            //    window.location = "/login"
          });;
      } else {
        axios
          .post(
            Constants.api + "company/cProduct/category",
            {
              "name": this.state.name,
              "code": this.state.code,
              "active": this.state.active,

            },
            {
              headers: {
                Authorization: `Bearer ${token}`, company: id, program: 0, appVersion: Constants.av,
                platform: Constants.pt
              },
            }
          )
          .then((res) => {
            if (res.status == 201) {
              swal("Product Catgory Added");
              this.setState({
                name: '',
                code: '',
                active: false
              });
            }  else if (res.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          })
          .catch((err) => {
            //localStorage.clear();
            //    window.location = "/login"
          });;
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };

  render() {
    if (this.state.create == 0) {
      window.location.reload();
    }

    let search = this.state.search;
    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const addedContent = [...that.state.addedContent];
        const item = addedContent.splice(fromIndex, 1)[0];
        addedContent.splice(toIndex, 0, item);
        that.setState({ addedContent });
      },
      nodeSelector: "li",
      handleSelector: ".mcq_arrow",
    };
    return (
      <>
        <main>

          <nav class="navbar navbar-main navbar-expand-lg position-sticky mt-4 top-1 px-0 mx-4 shadow-none border-radius-xl z-index-sticky" id="navbarBlur" data-scroll="true">
            <div class="container-fluid py-1 px-3">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">

                  <li class="breadcrumb-item text-sm"><a class="opacity-5 text-dark" onClick={() => this.setState({ create: 0 })}>Company Products Categories</a></li>
                  <li class="breadcrumb-item text-sm text-dark active" aria-current="page">Add New</li>
                </ol>
              </nav>

              <div class="sidenav-toggler sidenav-toggler-inner d-xl-block ">

                {/* <a href="javascript:;" class="nav-link text-body p-0">
                  <div class="sidenav-toggler-inner">
                    <i class="sidenav-toggler-line"></i>
                    <i class="sidenav-toggler-line"></i>
                    <i class="sidenav-toggler-line"></i>
                  </div>
                </a> */}
              </div>

            </div>
          </nav>
          <div class="card mt-4" id="basic-info">
            <div class="card-header">
              <h5>Category Info</h5>
            </div>
            <form onSubmit={this.handleFormSubmit}>
              <div class="card-body pt-0">

                <div class="row">
                  <div class="col-md-12">
                    <label class="form-label mt-4">Name</label>
                    <input class="form-control" id="choices-skills" type="text" placeholder="" name="name" value={this.state.name} onChange={this.nameHandleChange} />
                    {this.validator.message(
                      "Category Name",
                      this.state.name,
                      "required"
                    )}
                  </div>

                </div>

                <div class="row">

                </div>
                <div class="row">

                  <div class="col-md-4">
                    <label class="form-label mt-4">Code</label>
                    <input class="form-control" id="choices-skills" type="text" placeholder="" name="code" value={this.state.code} onChange={this.codeHandleChange} />
                    {this.validator.message(
                      "Code",
                      this.state.code,
                      "required"
                    )}
                  </div>


                  <div class="col-md-4">
                    <label class="form-label mt-4">Active?</label>
                    <br />
                    <Switch class="form-control" onChange={this.activeHandleChange} checked={this.state.active} />
                  </div>
                </div>

                <div class=" mt-4" id="delete">
                  <div class="card-body">
                    <div class="d-flex align-items-center mb-sm-0 mb-4">
                      <div class="w-50">
                        {/* <p>Save Country</p> */}
                        {/* <p class="text-sm mb-0">Once you delete your account, there is no going back. Please be certain.</p> */}
                      </div>
                      <div class="w-50 text-end">
                        <button class="btn btn-outline-secondary mb-3 mb-md-0 ms-auto" type="button" name="button" onClick={() => this.setState({ create: 0 })}>Cancel</button>
                        <button class="btn bg-gradient-success mb-0 ms-2" type="submit" name="button" >Save </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

        </main>

      </>
    );
  }

}

export default CreateCpCategories;
