import React, { Component } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import CreateProgramMembers from "./createprogramMembers.component";
import Modal from 'react-modal';

import '../../pages/modal/modal.style.css'; // Import a custom CSS file for styling

class ProgramMembers extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    // this.getFoodRequest = this.getFoodRequest.bind(this);
    // this.handleClickActiveTab = this.handleClickActiveTab.bind(this);
  }

  state = {
    name: "",
    sortBy: "name",
    limit: 25,
    totalResults: 0,
    page: 1,
    members: [],
    search: "",
    create: 0,
    view: 0,
    verifyModal: false,
    status: "",
    remarks: "",
    reason: "",
    memberId: 0
  };

  componentDidMount() {
    const currentURL = window.location.pathname;
    const parts = currentURL.split("/");
    let programid = parts.pop();
    programid = parseInt(programid);
    const pathSegments = window.location.pathname.split('/'); // Split the URL path by '/'
    let compid = pathSegments[pathSegments.length - 2];
    const memberRequest = async () => {

      try {
        const token = localStorage.getItem("token");
        let url = "";
        this.state.search != ""
          ? (url =
            "user/manage?" +
            "name=" +
            this.state.search +
            "&status=NEWSALES" +
            "&mobile=" +
            this.state.search +
            "&userType=MEMBER" +
            "&sortBy=" +
            this.state.sortBy +
            "&limit=" +
            this.state.limit +
            "&page=" +
            this.state.page)
          : (url =
            "user/manage?sortBy=" +
            this.state.sortBy +
            "&userType=MEMBER" +
            "&status=NEWSALES" +

            "&limit=" +
            this.state.limit +
            "&page=" +
            this.state.page);
        const resp = await axios
          .get(Constants.api + url, {
            headers: {
              Authorization: `Bearer ${token}`, company: compid, program: programid, appVersion: Constants.av,
              platform: Constants.pt
            },

          })
          .then((resp) => {
            //  members array

            if (resp.status == 201 && resp.data.result != "error") {
              console.log(resp.data.output.results);
              this.setState({
                members: resp.data.output.results,
              });
              console.log(this.state.tiers);
            }  else if (resp.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
            }
          }).catch((err) => {
            // window.location = "/login"
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    memberRequest();
  }

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount
    );
  };
  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ page: pageNumber }, this.componentDidMount);
  }


  render() {
    const approveMember = (id) => {
      try {
        const token = localStorage.getItem("token");

        axios
          .post(
            Constants.api + "user/manage/updatestatus/" + id, {
            status: this.state.status,
            remarks: this.state.remarks,
            reason: this.state.reason
          },
            {
              headers: {
                Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("language"), appVersion: Constants.av,
                platform: Constants.pt
              },
            },

          )
          .then((resp) => {
            //  casetype array
            swal("Status Updated")
            if (resp.status == 200) {
              this.setState({ verifyModal: false }, this.componentDidMount());

            }  else if (resp.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
            }
            //movie array
          }).catch((err) => {
            //  window.location = "/login"
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    const editItem = (id) => {
      try {
        const token = localStorage.getItem("token");
        const deletedata = new FormData();
        deletedata.append("id", id); //append the values with key, value pair

        axios
          .get(
            Constants.api + "user/manage/" + id,
            {
              headers: { Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("language") },
            },
            deletedata
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              this.setState({ create: 1, resp: resp });
            }  else if (resp.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
            }
            //movie array
          }).catch((err) => {
            //  window.location = "/login"
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    const viewItem = (id) => {
      try {
        const token = localStorage.getItem("token");
        const deletedata = new FormData();
        deletedata.append("id", id); //append the values with key, value pair

        axios
          .get(
            Constants.api + "user/manage/" + id,
            {
              headers: { Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("language") },
            },
            deletedata
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              this.setState({ view: 1, create: 1, resp: resp });
            }  else if (resp.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
            }
            //movie array
          }).catch((err) => {
            //  window.location = "/login"
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    const deleteItem = (id) => {
      if (window.confirm("Are you sure?")) {
        try {
          const token = localStorage.getItem("token");
          const deletedata = new FormData();
          deletedata.append("id", id); //append the values with key, value pair

          axios
            .delete(
              Constants.api + "user/manage/" + id,
              {
                headers: { Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("language") },
              }


            )
            .then((resp) => {
              //  casetype array

              if (resp.status == 200) {
                this.componentDidMount();
              }  else if (resp.status == 403) {
                localStorage.clear();
                window.location.href = "/login";
              } else {
              }
              //movie array
            });
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      }
    };
    let verifyModal = (id) => {
      this.setState({ verifyModal: !this.state.verifyModal, memberId: id })
    }
    const membersRender = this.state.members.map(function (item, i) {
      return (
        <>
          <tr>
            <td>
              {item.name}
            </td>
            <td>
              <p>{item.mobile}</p>
            </td>
            <td>
              <p>{item.city}</p>
            </td>
            <td>
              <p>{item.state}</p>
            </td>
            <td class="date_two">
              <p>{item.status}</p>
            </td>

            <td class="date_two">
              {moment(item.createdAt).format("MM/DD/YY H:ma")}
            </td>

            <td>

              <button class="btn btn-outline-primary btn-sm" onClick={() => verifyModal(item.id)}
              ><i class="material-icons text-lg">check_circle</i> Verify</button>

              <button class="btn btn-outline-primary btn-sm" onClick={() => editItem(item.id)}
              ><i class="material-icons text-lg">edit</i> Edit</button>
              &nbsp;
            </td>
          </tr>
        </>
      );
    });

    return (
      <>
        {this.state.create == 0 ? (
          <>

            <main class="">
              <nav class="navbar navbar-main navbar-expand-lg position-sticky mt-4 top-1 px-0 mx-4 shadow-none border-radius-xl z-index-sticky" id="navbarBlur" data-scroll="true">
                <div class="container-fluid py-1 px-3">
                  <nav aria-label="breadcrumb">

                  </nav>
                  <div class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none ">
                    <a class="nav-link text-body p-0">
                      <div class="sidenav-toggler-inner">
                        <i class="sidenav-toggler-line"></i>
                        <i class="sidenav-toggler-line"></i>
                        <i class="sidenav-toggler-line"></i>
                      </div>
                    </a>
                  </div>
                  <div class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
                    <div class="ms-md-auto pe-md-3 d-flex align-items-center">
                      <div class="input-group input-group-outline">
                        <input type="text" class="form-control" placeholder="Search"
                          onChange={this.searchHandleChange} />
                      </div>
                    </div>
                    <ul class="navbar-nav  justify-content-end">
                      <li class="nav-item">
                        <div class="from_dropdown">
                          <label for="show">Show entries</label>
                          <select name="cars" id="show">
                            <option
                              value="25"
                              onClick={
                                (() => this.setState({ limit: 25 }),
                                  () => this.componentDidMount())
                              }
                            >
                              25
                            </option>
                            <option
                              value="50"
                              onClick={
                                (() => this.setState({ limit: 50 }),
                                  () => this.componentDidMount())
                              }
                            >
                              50
                            </option>
                            <option
                              value="75"
                              onClick={
                                (() => this.setState({ limit: 75 }),
                                  () => this.componentDidMount())
                              }
                            >
                              75
                            </option>
                            <option
                              value="100"
                              onClick={
                                (() => this.setState({ limit: 100 }),
                                  () => this.componentDidMount())
                              }
                            >
                              100
                            </option>
                          </select>
                        </div>
                      </li>

                    </ul>
                  </div>
                </div>
              </nav>
              <div class="container-fluid py-4">
                <div class="row">
                  <div class="col-12">
                    <div class="card">
                      <div class="card-header pb-0">
                        <div class="d-lg-flex">
                          <div>
                            <h5 class="mb-0">New Members</h5>

                          </div>
                          <div class="ms-auto my-auto mt-lg-0 mt-4">
                            <div class="ms-auto my-auto">
                              <a href="#" class="btn bg-gradient-primary mb-2" onClick={() => this.setState({ create: 1 })}
                              > <i class="material-icons text-white position-relative text-md pe-2">add</i> Add New</a>
                              {this.state.verifyModal ? <>
                                <Modal
                                  isOpen={this.state.verifyModal}
                                  onRequestClose={() => this.setState({ verifyModal: false })}
                                  className="custom-modal"
                                  overlayClassName="custom-modal-overlay"
                                >
                                  <center>

                                    <div class="row">
                                      <div class="col-sm-12">
                                        <div class=" mx-4 input-group-outline my-2">
                                          Status
                                        </div>
                                        <div class=" mx-4 input-group-outline my-2">
                                          <select value={this.state.status} name="status" class="form-control" onChange={(e) =>
                                            this.handleInputChange(e)
                                          } >
                                            <option value="">Select</option>

                                            <option value="APPROVED">APPROVED</option>

                                            <option value="REJECT">REJECT</option>
                                          </select>
                                        </div>
                                      </div>

                                    </div>
                                    <div class="row">
                                      <div class="col-sm-12">
                                        <div class=" mx-4 input-group-outline my-2">
                                          Remarks
                                        </div>
                                        <div class=" mx-4 input-group-outline my-2">
                                          <textarea class="form-control" name="remarks" onChange={(e) =>
                                            this.handleInputChange(e)
                                          } >{this.state.remarks}</textarea>
                                        </div>
                                      </div>

                                    </div>
                                    {this.state.status == 'REJECT' ?
                                      <div class="row">
                                        <div class="col-sm-12">
                                          <div class=" mx-4 input-group-outline my-2">
                                            Reason
                                          </div>
                                          <div class=" mx-4 input-group-outline my-2">
                                            <select value={this.state.remarks} name="remarks" class="form-control" onChange={(e) =>
                                              this.handleInputChange(e)
                                            } >
                                              <option value="">Select Reason</option><option value="Incorrect KYC Documents">Incorrect KYC Documents</option><option value="Not a Genuine Plumber">Not a Genuine Plumber</option><option value="Duplicate Plumber">Duplicate Plumber</option><option value="other">Other</option>
                                            </select>
                                          </div>
                                        </div>
                                      </div> : ''}
                                    <div class="row">
                                      <div class="col-sm-12">  <a href="#!" class="btn bg-gradient-default mb-2" onClick={() => verifyModal(this.state.memberId)}
                                      >  Cancel</a>&nbsp;
                                        <a href="#!" class="btn bg-gradient-primary mb-2" onClick={() => approveMember(this.state.memberId)}
                                        >  Save Status</a>
                                      </div>
                                    </div>
                                  </center>

                                </Modal>
                              </>
                                : <></>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-body px-0 pb-0">
                        <div class="table-responsive">
                          <table class="table table-flush" id="products-list">
                            <thead class="thead-light">
                              <tr>

                                <th >
                                  Name
                                </th>
                                <th >
                                  Mobile   </th>
                                <th >
                                  City </th>
                                <th >
                                  State </th>
                                <th >
                                  Status </th>
                                <th >
                                  Created On
                                </th>

                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {membersRender}

                            </tbody>

                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </main>
            <div className="pagination-container">
              <Pagination
                activePage={this.state.page}
                itemsCountPerPage={this.state.limit}
                totalItemsCount={this.state.totalResults}
                pageRangeDisplayed={20}
                onChange={this.handlePageChange.bind(this)}
              />
            </div>

          </>
        ) : this.state.resp ? (

          <CreateProgramMembers
            resp={this.state.resp} />
        ) : (
          <CreateProgramMembers
          />
        )
        }

      </>
    );
  }
}

export default ProgramMembers;
