import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import axios from 'axios';
import * as Constants from "../../Constants/index"
import swal from 'sweetalert';
import { saveAs } from 'file-saver';

import ResponsivePagination from 'react-responsive-pagination';
export default function RedemptionReport() {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [results, setResults] = useState([])
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState('10')
  const [errors, setErrors] = useState({});
  const [searchRecord, setSearchRecord] = useState()
  const [status, setStatus] = useState('')
  const [loading, setLoading] = useState(false);

  const dateString = fromDate;
  const formattedDate = moment(dateString).format('YYYY-MM-DD HH:mm:ss');
  const dateString2 = toDate;
  const formattedDate2 = moment(dateString2).format('YYYY-MM-DD 23:59:59');
  const start = formattedDate.replace(/:/g, '%3A')
  const end = formattedDate2.replace(/:/g, '%3A')

  useEffect(() => {
    let token = localStorage.getItem('token');
    try {
      setLoading(true);
      axios.get(Constants.api + `reports/redemptionList/list?&start=${fromDate === "" ? fromDate : start}&end=${toDate === '' ? toDate : end}&sortBy=createDateTime&sortAsc=true&limit=${limit}&page=${currentPage}`, {

        headers: {
          Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
          appVersion: Constants.av,
          platform: Constants.pt,
          company: 1,
          program: 1,
        }
      })
        .then((res) => {
          if (res.status === 201) {
            if (res.data.code === 10001) {
              setResults(res.data.output.results)
              setTotalPages(res.data.output.totalPages);
              setSearchRecord()
              setLoading(false);
            } else if (res.data.code === 12002) {
              setLoading(false);
              setSearchRecord(res.data.output.results)
              setTotalPages(res.data.output.totalPages);
            } else {
              swal({
                text: res.data.msg,
                icon: 'error',
                timer: 2000,
                buttons: false,
              })
            }
          } else {
            swal({
              text: res.data.msg,
              icon: 'error',
              timer: 2000,
              buttons: false,
            })
          }
        })

    } catch (error) {
      console.log(error)
    }


  }, [currentPage])


  const validateForm = () => {
    let errors = {};

    if (!fromDate) {
      errors.fromDate = 'From Date is required';
    }

    if (!toDate) {
      errors.toDate = 'To Date is required';
    }
    if (!status) {
      errors.status = 'Status is required';
    }
    return errors;
  };

  const handleSearch = async () => {

    let token = localStorage.getItem('token');
    setErrors({});
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return; // Don't proceed with the API call if there are validation errors
    }
    try {
      setLoading(true)
      await axios.get(Constants.api + `reports/redemptionList/list?status=ORDERED&start=${start}&end=${end}&sortBy=createDateTime&sortAsc=true&limit=${limit}&page=${currentPage}`, {

        headers: {
          Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
          appVersion: Constants.av,
          platform: Constants.pt,
          company: 1,
          program: 1,
        }
      })
        .then((res) => {
          if (res.status === 201) {
            if (res.data.code === 10001) {
              setLoading(false)
              setResults(res.data.output.results)
              setTotalPages(res.data.output.totalPages);
              setSearchRecord()
              window.scrollTo({
                top: 370,
                behavior: 'smooth',
              });
            } else if (res.data.code === 12002) {
              setLoading(false)
              setSearchRecord(res.data.output.results)
              setTotalPages(res.data.output.totalPages);
            } else {
              swal({
                text: res.data.msg,
                icon: 'error',
                timer: 2000,
                buttons: false,
              })
            }
          } else {
            swal({
              text: res.data.msg,
              icon: 'error',
              timer: 2000,
              buttons: false,
            })
          }
        })

    } catch (error) {
      console.log(error)
    }

  }

  const download = () => {
    let token = localStorage.getItem('token');
    try {
      setLoading(true)
      axios.get(Constants.api + `reports/download/redeemreport?status=ORDERED&userType=MEMBER&start=${fromDate === "" ? fromDate : start}&end=${toDate === '' ? toDate : end}`, {

        headers: {
          Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
          appVersion: Constants.av,
          platform: Constants.pt,
          company: 1,
          program: 1,
        },
        responseType: 'blob', // Set the responseType to 'blob'

      })
        .then((res) => {
          if (res.status === 200) {
            setLoading(false)
            const fileData = new Blob([res.data], { type: 'text/csv' });
            saveAs(fileData, 'redeemReports.csv');
          } else {
            swal({
              text: res.data.msg,
              // icon: 'error',
              timer: 2000,
              buttons: false,
            })
          }
        })
    } catch (error) {
      console.log(error)
    }
  }

  // const handleKeyDown = (event) => {
  //     if (event.key === 'Enter') {
  //         handleSearch();
  //     }
  // };
  return (
    <>


      <section class="blog-area pt182">
        <div class="container">
          <div class="row">
            <div class="col-md-12 tab mb-20">

            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="all-single-blog rpt">
                <div class="row clearfix mb-15">
                  {/* <div class="col-md-3 col-sm-12 col-xs-12">
                                        <div class="form-group has-float-label style-two">
                                            <label>Zonal <sup>*</sup> </label>
                                            <select class="form-control2" style={{ color: "Gray" }}>
                                                <option selected="selected" value="0">All </option>

                                            </select>
                                        </div>
                                    </div> */}
                  {/* <div class="col-md-3 col-sm-12 col-xs-12">
                                        <div class="form-group has-float-label style-two">
                                            <label>State Head <sup>*</sup></label>
                                            <select class="form-control2" style={{ color: "Gray" }}>
                                                <option selected="selected" value="0">All </option>

                                            </select>
                                        </div>
                                    </div> */}

                  {/* <div class="col-md-3 col-sm-12 col-xs-12">
                                        <div class="form-group has-float-label style-two">
                                            <label>Sales Team <sup>*</sup> </label>
                                            <select class="form-control2" style={{ color: "Gray" }}>
                                                <option selected="selected" value="0">All </option>

                                            </select>
                                        </div>
                                    </div> */}

                  <div class="col-md-3 col-sm-12 col-xs-12">
                    <div class="form-group has-float-label style-two">
                      <label>From Date<sup>*</sup> </label>
                      {/* <DatePicker
                                                selected={fromDate}
                                                onChange={(date) => setFromDate(date)}
                                                dateFormat="dd-MM-yyyy HH:mm:ss"
                                                className="form-control"
                                                id="Fromdate"
                                                showTimeSelect
                                                timeFormat="HH:mm:ss"
                                                // timeIntervals={1}
                                                timeCaption="Time"
                                                value={fromDate ? moment(fromDate).format('DD-MM-YYYY HH:mm:ss') : ''}
                                                showYearDropdown
                                                showMonthDropdown
                                                autoComplete="off"
                                                scrollableYearDropdown
                                                maxDate={new Date()}
                                            /> */}
                      <DatePicker
                        selected={fromDate}
                        onChange={(date) => setFromDate(date)}
                        dateFormat="dd-MM-yyyy"
                        className="form-control"
                        id="Fromdate"
                        showYearDropdown
                        showMonthDropdown
                        autoComplete="off"
                        scrollableYearDropdown
                        maxDate={new Date()}
                        onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                        onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                        onClick={(e) => e.preventDefault()} // Preven
                      />
                    </div>
                    {errors.fromDate && (
                      <div className="error-message">{errors.fromDate}</div>
                    )}
                  </div>
                  <div class="col-md-3 col-sm-12 col-xs-12">
                    <div class="form-group has-float-label style-two">
                      <label>To Date<sup>*</sup> </label>
                      {/* <DatePicker
                                                selected={toDate}
                                                onChange={(date) => setToDate(date)}
                                                dateFormat="dd-MM-yyyy HH:mm:ss"
                                                className="form-control"
                                                id="Fromdate"
                                                showTimeSelect
                                                timeFormat="HH:mm:ss"
                                                // timeIntervals={1}
                                                timeCaption="Time"
                                                value={toDate ? moment(toDate).format('DD-MM-YYYY HH:mm:ss') : ''}
                                                showYearDropdown
                                                showMonthDropdown
                                                autoComplete="off"
                                                scrollableYearDropdown
                                                maxDate={new Date()}
                                            /> */}
                      <DatePicker
                        selected={toDate}
                        onChange={(date) => setToDate(date)}
                        dateFormat="dd-MM-yyyy"
                        className="form-control"
                        id="todate"
                        showYearDropdown
                        showMonthDropdown
                        autoComplete="off"
                        scrollableYearDropdown
                        maxDate={new Date()}
                        onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                        onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                        onClick={(e) => e.preventDefault()} // Preven
                      />
                    </div>
                    {errors.toDate && (
                      <div className="error-message">{errors.toDate}</div>
                    )}
                  </div>
                  <div class="col-md-3 col-sm-12 col-xs-12">
                    <div class="form-group has-float-label style-two">
                      <label>Select Status <sup>*</sup> </label>
                      <select class="form-control2" style={{ color: "Gray" }} value={status} onChange={(event) => setStatus(event.target.value)}>
                        <option selected="selected" value="1">All </option>
                        <option value="Pending for Approval">Pending for Approval </option>
                        <option value="In-Process">In-Process </option>
                        <option value="Delivered">Delivered </option>
                        <option value="Rejected">Rejected </option>
                      </select>
                    </div>
                    {errors.status && (
                      <div className="error-message">{errors.status}</div>
                    )}
                  </div>
                  <div class="col-md-12 col-sm-12 col-xs-12 text-center">
                    <div class="form-group has-float-label style-two">
                      <label class="invisible" style={{ width: "100%" }}>hidden</label>
                      <input type="button" class="btn login_btn btn-primary" value="Search" id="btnSearch" onClick={handleSearch} />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="carrd1">
                      <div class="card-header card-header-warning">
                        <h4 class="card-title">Redemption Report
                          {searchRecord === null ? '' :
                            <button className='btn-xs  btn btn-primary'>  <Link to="#" class="pull-right" onClick={download} style={{ color: "white" }}><i class="fa fa-file-excel-o "></i> Export</Link></button>}
                        </h4>
                      </div>
                      <div class="card-body table-responsive">
                        <table class="table table-striped mb_01" id="table-to-xls">
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Redemption ID</th>
                              <th>	Member Name</th>
                              <th>	Mobile Number</th>
                              <th>	State</th>
                              <th>	City</th>
                              <th>	Date Of Request</th>
                              <th>	DOJ</th>
                              <th>	Sales/Marketing Team Name</th>
                              <th>	Total Earned Stars</th>
                              <th>	Redeemed Stars</th>
                              <th>	Redemption Request Date</th>
                              <th>	Redemption Processed Date</th>
                              <th>	Redemption Details	</th>
                              {/* <th>Paytm Number</th> */}
                              <th>	Account Number</th>
                              <th>	Account Holder Name	</th>
                              <th>IFSC Code	</th>
                              <th>Bank Name</th>
                              <th>	Status</th>
                              <th>	VendorErrorRemarks	</th>
                              {/* <th>VendorOrderID</th> */}
                            </tr>
                          </thead>
                          {searchRecord === null ?
                            <tbody>
                              <tr>
                                <td colSpan="11" style={{ textAlign: 'center', fontSize: '16px' }}>No record found!</td>
                              </tr>
                            </tbody> :
                            <tbody>
                              {results.map((item, index) => {
                                const serialNumber = (currentPage - 1) * limit + index + 1;
                                const doj = moment(item.doj).format('DD-MM-YYYY')
                                const dateOfRequest = moment(item.dateOfRequest).format('DD-MM-YYYY')
                                return (
                                  <>
                                    <tr key={index}>
                                      <td>{serialNumber}</td>
                                      <td>{item.redemptionId}</td>
                                      <td>{item.name}</td>
                                      <td>{item.mobile}</td>
                                      <td>{item.state}</td>
                                      <td>{item.city}</td>
                                      <td>{dateOfRequest}</td>
                                      <td>{doj}</td>
                                      <td>{item.salesTeamName}</td>
                                      <td>{item.totalStars}</td>
                                      <td>{item.redeemedStars}</td>
                                      <td>{item.dateOfRequest}</td>
                                      <td>{item.processedDate}</td>
                                      <td>{item.redeemDetails}</td>
                                      {/* <td>{item.paytmNumber}</td> */}
                                      <td>{item.accountNumber}</td>
                                      <td>{item.acountHolderName}</td>
                                      <td>{item.ifscCode}</td>
                                      <td>{item.bankName}</td>
                                      <td>{item.status}</td>
                                      <td>{item.vendorRemarks}</td>
                                      {/* <td>{item.vendorOrderId}</td> */}
                                    </tr>
                                  </>
                                )
                              })}
                            </tbody>}
                        </table>
                        {totalPages > 1 && (
                          <ResponsivePagination
                            current={currentPage}
                            total={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >

    </>
  )
}
