import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import axios from 'axios';
import * as Constants from "../../Constants/index"
import { saveAs } from 'file-saver';

import swal from 'sweetalert';
import ResponsivePagination from 'react-responsive-pagination';
import moment from 'moment';
export default function SalesQrTransactionReport() {
  const [searchQuery, setSearchQuery] = useState('')
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState('10')
  const [errors, setErrors] = useState({});
  const [results, setResults] = useState([])
  const [searchRecord, setSearchRecord] = useState()


  useEffect(() => {
    let token = localStorage.getItem('token');
    try {
      axios.get(Constants.api + `reports/qrTrans/list?mobile=${searchQuery}&userType=MEMBER&limit=${limit}&page=${currentPage}`, {

        headers: {
          Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
          appVersion: Constants.av,
          platform: Constants.pt,
          company: 1,
          program: 1,
        }
      })
        .then((res) => {
          if (res.status === 201) {
            if (res.data.code === 10001) {
              setResults(res.data.output.results)
              setTotalPages(res.data.output.totalPages);
              setSearchRecord()

            } else if (res.data.code === 12002) {

              setSearchRecord(res.data.output.results)
              setTotalPages(res.data.output.totalPages);
            } else {
              swal({
                text: res.data.msg,
                icon: 'error',
                timer: 2000,
                buttons: false,
              })
            }
          }  else if (res.status == 403) {
            localStorage.clear();
            window.location.href = "/login";
          } else {
            swal({
              text: res.data.msg,
              icon: 'error',
              timer: 2000,
              buttons: false,
            })
          }
        })

    } catch (error) {
      console.log(error)
    }


  }, [currentPage])


  const validateForm = () => {
    let errors = {};
    if (!searchQuery) {
      errors.searchQuery = 'Mobile is required';
    }
    return errors;
  };
  const handleSearch = async () => {
    let token = localStorage.getItem('token');
    setErrors({});
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return; // Don't proceed with the API call if there are validation errors
    }
    try {
      await axios.get(Constants.api + `reports/qrTrans/list?mobile=${searchQuery}&userType=MEMBER&limit=${10}&page=${currentPage}`, {

        headers: {
          Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
          appVersion: Constants.av,
          platform: Constants.pt,
          company: 1,
          program: 1,
        }
      })
        .then((res) => {
          if (res.status === 201) {
            if (res.data.code === 10001) {
              setResults(res.data.output.results)
              setTotalPages(res.data.output.totalPages);
              setSearchRecord()
              window.scrollTo({
                top: 370,
                behavior: 'smooth',
              });
            } else if (res.data.code === 12002) {

              setSearchRecord(res.data.output.results)
              setTotalPages(res.data.output.totalPages);
            }  else if (res.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
              swal({
                text: res.data.msg,
                icon: 'error',
                timer: 2000,
                buttons: false,
              })
            }
          } else {
            swal({
              text: res.data.msg,
              icon: 'error',
              timer: 2000,
              buttons: false,
            })
          }
        })
    } catch (error) {
      console.log(error)
    }

  }


  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const download = () => {
    let token = localStorage.getItem('token');
    try {
      axios.get(Constants.api + `reports/download/qrTransreport?userType=MEMBER&mobile=${searchQuery}`, {

        headers: {
          Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
          appVersion: Constants.av,
          platform: Constants.pt,
          company: 1,
          program: 1,
        },
        responseType: 'blob', // Set the responseType to 'blob'

      })
        .then((res) => {
          if (res.status === 200) {

            const fileData = new Blob([res.data], { type: 'text/csv' });
            saveAs(fileData, 'qrTransactionReports.csv');
          }  else if (res.status == 403) {
            localStorage.clear();
            window.location.href = "/login";
          } else {
            swal({
              text: res.data.msg,
              // icon: 'error',
              timer: 2000,
              buttons: false,
            })
          }
        })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>

      <section class="blog-area pt182">
        <div class="container">

          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="all-single-blog rpt">
                <div class="row clearfix mb-15" style={{ display: "none" }}>
                  {/* <div class="col-md-3 col-sm-12 col-xs-12">
                                        <div class="form-group has-float-label style-two">
                                            <label>Zonal <sup>*</sup> </label>
                                            <select class="form-control2" style={{ color: "Gray" }}>
                                                <option selected="selected" value="0">All </option>

                                            </select>
                                        </div>
                                    </div> */}
                  {/* <div class="col-md-3 col-sm-12 col-xs-12">
                                        <div class="form-group has-float-label style-two">
                                            <label>State Head <sup>*</sup></label>
                                            <select class="form-control2" style={{ color: "Gray" }}>
                                                <option selected="selected" value="0">All </option>
                                            </select>
                                        </div>
                                    </div> */}
                  {/* <div class="col-md-3 col-sm-12 col-xs-12">
                                        <div class="form-group has-float-label style-two">
                                            <label>Sales Team <sup>*</sup> </label>
                                            <select class="form-control2" style={{ color: "Gray" }}>
                                                <option selected="selected" value="0">All </option>

                                            </select>
                                        </div>
                                    </div> */}
                  <div class="col-md-3 col-sm-12 col-xs-12">
                    <div class="form-group has-float-label style-two">
                      <label>Mobile No.<sup>*</sup> </label>
                      <input type="text" class="form-control" maxLength='10' value={searchQuery} onChange={(event) => {
                        const input = event.target.value;
                        if (/^\d*$/.test(input)) {
                          setSearchQuery(input);
                        }
                      }} onKeyDown={handleKeyDown} />
                    </div>
                    {errors.searchQuery && (
                      <div className="error-message">{errors.searchQuery}</div>
                    )}
                  </div>
                  <div class="col-md-12 col-sm-12 col-xs-12 text-center">
                    <div class="form-group has-float-label style-two">
                      <label class="invisible" style={{ width: "100%" }}>hidden</label>
                      <input type="button" class="btn login_btn" value="Search" id="btnSearch" onClick={handleSearch} />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="carrd1">
                      <div class="card-header card-header-warning">
                        <h4 class="card-title">QR Transaction Report
                          {searchRecord === null ? '' :
                            <Link to="#" class="btn btn-primary pull-right" onClick={download}><i class="fa fa-file-excel-o"></i> Export</Link>}
                        </h4>
                      </div>
                      <div class="card-body table-responsive">

                        <table class="table table-striped mb_01" id="table-to-xls">
                          <thead>
                            <tr>
                              <th>S.no</th>
                              <th>Member name</th>
                              <th>Mobile Number</th>
                              <th>DOJ	</th>
                              <th>State	</th>
                              <th>City	</th>
                              <th>Address	Registred By Sales/Marketing Name</th>
                              <th>Approved by Sales/Marketing Name</th>
                              <th>Date of Scan</th>
                              <th>Scan ID	</th>
                              <th>Product Category</th>
                              <th>Product SubCategory</th>
                              <th>SAP Code	</th>
                              <th>QR Details	</th>
                              {/* <th>ManufacturingDate	</th> */}
                              {/* <th>Dealer Number</th> */}
                              {/* <th>Dealer Name	</th> */}
                              <th>TransactionTime	</th>
                              <th>Base Stars</th>
                              <th>Stars	</th>
                              <th>Total Stars</th>
                              <th>Status</th>
                              <th>Remark</th>
                            </tr>
                          </thead>
                          {searchRecord === null ?
                            <tbody>
                              <tr>
                                <td colSpan="11" style={{ textAlign: 'center', fontSize: '16px' }}>No record found!</td>
                              </tr>
                            </tbody> :
                            <tbody>
                              {results.map((item, index) => {
                                const serialNumber = (currentPage - 1) * limit + index + 1;
                                const scanDate = moment(item.scanDateTime).format('DD-MM-YYYY')
                                const doj = moment(item.createDateTime).format('DD-MM-YYYY')
                                const transTime = moment(item.transTime).format('DD-MM-YYYY')
                                return (
                                  <>
                                    <tr>
                                      <td>{serialNumber}</td>
                                      <td>{item.name}</td>
                                      <td>{item.mobile}</td>
                                      <td>{doj}</td>
                                      <td>{item.state}</td>
                                      <td>{item.city}</td>
                                      <td>{item.addressRegBysales}</td>
                                      <td>{item.approvedBysales}</td>
                                      <td>{scanDate}</td>
                                      <td>{item.scanId}</td>
                                      <td>{item.productCategory}</td>
                                      <td>{item.productSubCategory}</td>
                                      <td>{item.sapCode}</td>
                                      <td>{item.qrDetails}</td>
                                      {/* <td>{item.manufacturingDate}</td> */}
                                      {/* <td>{item.delearNumber}</td> */}
                                      {/* <td>{item.delearName}</td> */}
                                      <td>{transTime}</td>
                                      <td>{item.baseStar}</td>
                                      <td>{item.star}</td>
                                      <td>{item.totalStar}</td>
                                      <td>{item.status}</td>
                                      <td>{item.resonOfRejection}</td>
                                    </tr>
                                  </>
                                )
                              })}

                            </tbody>}
                        </table>
                        {totalPages > 1 && (
                          <ResponsivePagination
                            current={currentPage}
                            total={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}
