import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import * as Constants from "../../Constants/index"
import swal from 'sweetalert';
import ResponsivePagination from 'react-responsive-pagination';
import { saveAs } from 'file-saver';

export default function LoginReport() {
  const [searchQuery, setSearchQuery] = useState('')
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState('10')
  const [errors, setErrors] = useState({});
  const [results, setResults] = useState([])
  const [searchRecord, setSearchRecord] = useState()


  useEffect(() => {
    let token = localStorage.getItem('token');
    try {
      axios.get(Constants.api + `reports/login/list?name=${searchQuery}&userType=MEMBER&sortBy=name&sortAsc=true&limit=${limit}&page=${currentPage}`, {

        headers: {
          Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
          appVersion: Constants.av,
          platform: Constants.pt,
          company: 1,
          program: 1,
        }
      })
        .then((res) => {
          if (res.status === 201) {
            if (res.data.code === 10001) {
              setResults(res.data.output.results)
              setTotalPages(res.data.output.totalPages);
              setSearchRecord()

            } else if (res.data.code === 12002) {

              setSearchRecord(res.data.output.results)
              setTotalPages(res.data.output.totalPages);
            } else {
              swal({
                text: res.data.msg,
                icon: 'error',
                timer: 2000,
                buttons: false,
              })
            }
          } else {
            swal({
              text: res.data.msg,
              icon: 'error',
              timer: 2000,
              buttons: false,
            })
          }
        })

    } catch (error) {
      console.log(error)
    }

  }, [currentPage])


  const validateForm = () => {
    let errors = {};

    if (!searchQuery) {
      errors.searchQuery = 'Name / Mobile is required';
    }

    return errors;
  };
  const handleSearch = async () => {
    let token = localStorage.getItem('token');
    setErrors({});
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return; // Don't proceed with the API call if there are validation errors
    }
    try {
      await axios.get(Constants.api + `reports/login/list?name=${searchQuery}&userType=MEMBER&sortBy=name&sortAsc=true&limit=${limit}&page=${currentPage}`, {

        headers: {
          Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
          appVersion: Constants.av,
          platform: Constants.pt,
          company: 1,
          program: 1,
        }
      })
        .then((res) => {
          if (res.status === 201) {
            if (res.data.code === 10001) {
              setResults(res.data.output.results)
              setTotalPages(res.data.output.totalPages);
              setSearchRecord()
              window.scrollTo({
                top: 370,
                behavior: 'smooth',
              });
            } else if (res.data.code === 12002) {
              // swal({
              //     text: res.data.msg,
              //     // icon: 'error',
              //     timer: 1000,
              //     buttons: false,
              // })
              setSearchRecord(res.data.output.results)
              setTotalPages(res.data.output.totalPages);
            } else {
              swal({
                text: res.data.msg,
                // icon: 'error',
                timer: 2000,
                buttons: false,
              })
            }
          } else {
            swal({
              text: res.data.msg,
              // icon: 'error',
              timer: 2000,
              buttons: false,
            })
          }
        })

    } catch (error) {
      console.log(error)
    }

  }
  const download = () => {
    let token = localStorage.getItem('token');
    try {
      axios.get(Constants.api + `reports/download/loginreport?userType=MEMBER&name=${searchQuery}`, {

        headers: {
          Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
          appVersion: Constants.av,
          platform: Constants.pt,
          company: 1,
          program: 1,
        },
        responseType: 'blob', // Set the responseType to 'blob'

      })
        .then((res) => {
          if (res.status === 200) {

            const fileData = new Blob([res.data], { type: 'text/csv' });
            saveAs(fileData, 'loginReport.csv');
          } else {
            swal({
              text: res.data.msg,
              // icon: 'error',
              timer: 2000,
              buttons: false,
            })
          }
        })
    } catch (error) {
      console.log(error)
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };


  return (
    <>

      <section className="blog-area pt182">
        <div className="container">
          <div className="row">
            <div className="col-md-12 tab mb-20">
              <ul className="nav nav-tabs" role="tablist">
                <li role="presentation" className="active"><Link to="#Section1" role="tab" data-toggle="tab">Login Report</Link></li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="all-single-blog rpt">
                <div className="row clearfix mb-15">
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>Zonal <sup>*</sup> </label>
                      <select className="form-control2" style={{ color: "Gray" }}>
                        <option selected="selected" value="0">All </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>State Head <sup>*</sup></label>
                      <select className="form-control2" style={{ color: "Gray" }}>
                        <option selected="selected" value="0">All </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>Sales Team <sup>*</sup> </label>
                      <select className="form-control2" style={{ color: "Gray" }}>
                        <option selected="selected" value="0">All </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>Name / Mobile No.<sup>*</sup> </label>
                      <input type="text" className="form-control" value={searchQuery} onChange={(event) => setSearchQuery(event.target.value)} onKeyDown={handleKeyDown} />
                    </div>
                    {errors.searchQuery && (
                      <div className="error-message">{errors.searchQuery}</div>
                    )}
                  </div>
                  <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                    <div className="form-group has-float-label style-two">
                      <label className="invisible" style={{ width: "100%" }}>hidden</label>
                      <input type="button" className="btn login_btn" value="Search" id="btnSearch" onClick={handleSearch} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="carrd1">
                      <div className="card-header card-header-warning">
                        <h4 className="card-title"> Login Report
                          {searchRecord === null ? '' :
                            <Link to="#" class="pull-right" onClick={download}><i class="fa fa-file-excel-o"></i> Export</Link>}
                        </h4>
                      </div>
                      <div className="card-body table-responsive">
                        <table className="table table-striped mb_01" id="table-to-xls">
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Member Name</th>
                              <th>Mobile Number</th>
                              <th>Registration Type</th>
                              <th>Date of Registration</th>
                              <th>Validation Status</th>
                              <th>Welcome Point status</th>
                              {/* <th>First Login Date</th> */}
                              {/* <th>Last Login Date</th> */}
                              <th>Total Stars Earned to Date</th>
                              <th>Total Stars Redeemed to Date</th>
                            </tr>
                          </thead>
                          {searchRecord === null ?
                            <tbody>
                              <tr>
                                <td colSpan="10" style={{ textAlign: 'center', fontSize: '16px' }}>No record found!</td>
                              </tr>
                            </tbody> :
                            <tbody>
                              {results.map((item, index) => {
                                const serialNumber = (currentPage - 1) * limit + index + 1;
                                // const status = item.approval ? item.approval.status : null;

                                return (
                                  <>
                                    <tr >
                                      <td>{serialNumber}</td>
                                      <td>{item.name}</td>
                                      <td>{item.mobile}</td>
                                      <td>{item.userType}</td>
                                      <td>{item.createDateTime}</td>
                                      <td>{item.status}</td>
                                      <td>{item.program.config.bonusWelcome}</td>
                                      {/* <td></td> */}
                                      {/* <td></td> */}
                                      <td>{item.totalPoints}</td>
                                      <td>{item.redeemPoints}</td>

                                    </tr>
                                  </>
                                )
                              })}
                            </tbody>}
                        </table>
                        {totalPages > 1 && (
                          <ResponsivePagination
                            current={currentPage}
                            total={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
