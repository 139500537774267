import React, { Component } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import CreateCompany from "./createcompany.component";

class Company extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    // this.getFoodRequest = this.getFoodRequest.bind(this);
    // this.handleClickActiveTab = this.handleClickActiveTab.bind(this);
  }

  state = {
    name: "",
    sortBy: "name",
    limit: 25,
    totalResults: 0,
    page: 1,
    companys: [],
    search: "",
    create: 0,
  };

  componentDidMount() {
    const companysRequest = async () => {
      try {
        const token = localStorage.getItem("token");
        let url = "";
        this.state.search != ""
          ? (url =
              "company?sortBy=" +
              this.state.sortBy +
              "&sortAsc=true" +
              "&limit=" +
              this.state.limit +
              "&name=" +
              this.state.search +
              "&page=" +
              this.state.page)
          : (url =
              "company?sortBy=" +
              this.state.sortBy +
              "&sortAsc=true" +
              "&limit=" +
              this.state.limit +
              "&name=" +
              this.state.search +
              "&page=" +
              this.state.page);
        const resp = await axios
          .get(Constants.api + url, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((resp) => {
            if (resp.status == 201 && resp.data.result == "success") {
              this.setState({
                companys: resp.data.output.results,
                totalResults: resp.data.output.totalResults,
              });
              // console.log(this.state.companys);
            } else if (resp.status == 403) {
              localStorage.removeItem("userid");
              window.location.href = "/login";
            } else {
            }
          })
          .catch((err) => {
            //localStorage.clear();
            //    window.location = "/login"
            //   }
          });
      } catch (err) {
        console.error(err);
      }
    };
    companysRequest();
  }

  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount
    );
  };
  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ page: pageNumber }, this.componentDidMount);
  }

  render() {
    let manageItem = (id) => {
      window.location = "/company/manage/" + id;
    };
    let programs = (id) => {
      window.location = "/company/masterprograms/" + id;
    };
    const editItem = (id) => {
      try {
        const token = localStorage.getItem("token");
        const deletedata = new FormData();
        deletedata.append("id", id); //append the values with key, value pair

        axios
          .get(
            Constants.api + "company/" + id,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Accept-Language": localStorage.getItem("language"),
              },
            },
            deletedata
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              this.setState({ create: 1, resp: resp });
            } else if (resp.status == 403) {
              localStorage.removeItem("userid");
              window.location.href = "/login";
            } else {
            }
            //movie array
          })
          .catch((err) => {
            //  window.location = "/login"
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    const deleteItem = (id) => {
      if (window.confirm("Are you sure?")) {
        try {
          const token = localStorage.getItem("token");
          const deletedata = new FormData();
          deletedata.append("id", id); //append the values with key, value pair

          axios
            .delete(Constants.api + "company/" + id, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Accept-Language": localStorage.getItem("language"),
              },
            })
            .then((resp) => {
              //  casetype array

              if (resp.status == 200) {
                this.componentDidMount();
              } else if (resp.status == 403) {
                localStorage.removeItem("userid");
                window.location.href = "/login";
              } else {
              }
              //movie array
            });
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      }
    };

    const companysRender = this.state.companys.map(function (item, i) {
      return (
        <>
          <tr>
            <td>
              <img src={item.logo} height="50" width="100" />
            </td>
            <td>{item.name}</td>
            <td>
              <p>{item.coi}</p>
            </td>

            <td class="date_two">
              {moment(item.createdAt).format("MM/DD/YY H:ma")}
            </td>
            <td class="date_two">
              {moment(item.updatedAt).format("MM/DD/YY H:ma")}
            </td>
            <td>
              <button
                class="btn btn-outline-primary btn-sm"
                onClick={() => editItem(item.id)}
              >
                <i class="material-icons text-lg">edit</i> Edit
              </button>
              &nbsp;
              <button
                class="btn btn-outline-primary btn-sm"
                onClick={() => manageItem(item.id)}
              >
                <i class="material-icons text-lg">settings</i> Manage
              </button>
              &nbsp;
              <button
                class="btn btn-outline-primary btn-sm"
                onClick={() => programs(item.id)}
              >
                <i class="material-icons text-lg">star</i> Program
              </button>
              &nbsp;
              {/* <button class="btn btn-outline-danger btn-sm" onClick={() => deleteItem(item.id)}
              ><i class="material-icons text-lg">delete</i> Delete</button> */}
            </td>
          </tr>
        </>
      );
    });

    return (
      <>
        {this.state.create == 0 ? (
          <>
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
              <nav
                class="navbar navbar-main navbar-expand-lg position-sticky mt-4 top-1 px-0 mx-4 shadow-none border-radius-xl z-index-sticky"
                id="navbarBlur"
                data-scroll="true"
              >
                <div class="container-fluid py-1 px-3">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
                      <li
                        class="breadcrumb-item text-sm text-dark active"
                        aria-current="page"
                      >
                        Company
                      </li>
                    </ol>
                  </nav>
                  <div class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none ">
                    <a class="nav-link text-body p-0">
                      <div class="sidenav-toggler-inner">
                        <i class="sidenav-toggler-line"></i>
                        <i class="sidenav-toggler-line"></i>
                        <i class="sidenav-toggler-line"></i>
                      </div>
                    </a>
                  </div>
                  <div
                    class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4"
                    id="navbar"
                  >
                    <div class="ms-md-auto pe-md-3 d-flex align-items-center">
                      <div class="input-group input-group-outline">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search"
                          onChange={this.searchHandleChange}
                        />
                      </div>
                    </div>
                    <ul className="navbar-nav justify-content-center">
                      <li className="nav-item">
                        <div
                          className="d-flex align-items-center from_dropdown"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <label htmlFor="show" className="me-2 mb-0">
                            Show entries
                          </label>
                          <div className="custom-select-container">
                            <select
                              name="cars"
                              id="show"
                              className="form-select custom-select"
                            >
                              <option
                                value="25"
                                onClick={() => {
                                  this.setState({ limit: 25 });
                                  this.componentDidMount();
                                }}
                              >
                                25
                              </option>
                              <option
                                value="50"
                                onClick={() => {
                                  this.setState({ limit: 50 });
                                  this.componentDidMount();
                                }}
                              >
                                50
                              </option>
                              <option
                                value="75"
                                onClick={() => {
                                  this.setState({ limit: 75 });
                                  this.componentDidMount();
                                }}
                              >
                                75
                              </option>
                              <option
                                value="100"
                                onClick={() => {
                                  this.setState({ limit: 100 });
                                  this.componentDidMount();
                                }}
                              >
                                100
                              </option>
                            </select>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
              <div class="container-fluid py-4">
                <div class="row">
                  <div class="col-12">
                    <div class="card">
                      <div class="card-header pb-0">
                        <div class="d-lg-flex">
                          <div>
                            <h5 class="mb-0">All Company</h5>
                          </div>
                          <div class="ms-auto my-auto mt-lg-0 mt-4">
                            <div class="ms-auto my-auto">
                              <a
                                href="#"
                                class="btn bg-gradient-primary mb-2"
                                onClick={() => this.setState({ create: 1 })}
                              >
                                {" "}
                                <i class="material-icons text-white position-relative text-md pe-2">
                                  add
                                </i>{" "}
                                Add New
                              </a>
                              <div
                                class="modal fade"
                                id="import"
                                tabindex="-1"
                                aria-hidden="true"
                              >
                                <div class="modal-dialog mt-lg-10">
                                  <div class="modal-content">
                                    <div class="modal-header">
                                      <h5 class="modal-title" id="ModalLabel">
                                        Import CSV
                                      </h5>
                                      <i class="material-icons ms-3">
                                        file_upload
                                      </i>
                                      <button
                                        type="button"
                                        class="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                    <div class="modal-body">
                                      <p>
                                        You can browse your computer for a file.
                                      </p>
                                      <div class="input-group input-group-dynamic mb-3">
                                        <label class="form-label">
                                          Browse file...
                                        </label>
                                        <input
                                          type="email"
                                          class="form-control"
                                          onfocus="focused(this)"
                                          onfocusout="defocused(this)"
                                        />
                                      </div>
                                      <div class="form-check">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="importCheck"
                                          checked=""
                                        />
                                        <label
                                          class="custom-control-label"
                                          for="importCheck"
                                        >
                                          I accept the terms and conditions
                                        </label>
                                      </div>
                                    </div>
                                    <div class="modal-footer">
                                      <button
                                        type="button"
                                        class="btn bg-gradient-secondary btn-sm"
                                        data-bs-dismiss="modal"
                                      >
                                        Close
                                      </button>
                                      <button
                                        type="button"
                                        class="btn bg-gradient-primary btn-sm"
                                      >
                                        Upload
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <button class="btn btn-outline-primary btn-sm export mb-0 mt-sm-0 mt-1" data-type="csv" type="button" name="button">Export</button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-body px-0 pb-0">
                        <div class="table-responsive">
                          <table class="table table-flush" id="products-list">
                            <thead class="thead-light">
                              <tr>
                                <th>Logo</th>
                                <th>Name</th>
                                <th>COI</th>
                                <th>Created On</th>
                                <th>Modified On</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>{companysRender}</tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pagination-container">
                  <Pagination
                    activePage={this.state.page}
                    itemsCountPerPage={this.state.limit}
                    totalItemsCount={this.state.totalResults}
                    pageRangeDisplayed={20}
                    onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>
            </main>
          </>
        ) : this.state.resp ? (
          <CreateCompany resp={this.state.resp} />
        ) : (
          <CreateCompany />
        )}
      </>
    );
  }
}

export default Company;
