import React, { Component, useEffect, useState } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import Switch from "react-switch";

class CreateTemplates extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      name: this.props.resp ? this.props.resp.data.name : "",
      emailBcc: this.props.resp ? this.props.resp.data.emailBcc : "",
      emailCc: this.props.resp ? this.props.resp.data.emailCc : "",
      emailBody: this.props.resp ? this.props.resp.data.emailBody : "",
      emailActive: this.props.resp ? this.props.resp.data.emailActive : false,

      smsBody: this.props.resp ? this.props.resp.data.smsBody : "",
      smsActive: this.props.resp ? this.props.resp.data.smsActive : false,
      waBody: this.props.resp ? this.props.resp.data.waBody : "",
      waActive: this.props.resp ? this.props.resp.data.waActive : false,
      notifyBody: this.props.resp ? this.props.resp.data.notifyBody : "",
      notifyActive: this.props.resp ? this.props.resp.data.notifyActive : false,
      sortBy: "name",
      limit: 1000,
      page: 1,
      searchTerm: "",
      search: "",
      create: 1,

    };
    this.emailActiveHandleChange = this.emailActiveHandleChange.bind(this);
    this.smsActiveHandleChange = this.smsActiveHandleChange.bind(this);
    this.waActiveHandleChange = this.waActiveHandleChange.bind(this);
    this.notifyActiveHandleChange = this.notifyActiveHandleChange.bind(this);

    // this.searchUpdated = this.searchUpdated.bind(this);
  }


  nameHandleChange = (event) => {
    this.setState({ name: event.target.value });
  };
  emailBccHandleChange = (event) => {
    this.setState({ emailBcc: event.target.value });
  };
  emailCcHandleChange = (event) => {
    this.setState({ emailCc: event.target.value });
  };
  emailBodyHandleChange = (event) => {
    this.setState({ emailBody: event.target.value });
  };
  emailActiveHandleChange = (checked) => {
    this.setState({ emailActive: checked });
  };
  smsBodyHandleChange = (event) => {
    this.setState({ smsBody: event.target.value });
  };
  smsActiveHandleChange = (checked) => {
    this.setState({ smsActive: checked });
  };
  smsBodyHandleChange = (event) => {
    this.setState({ smsBody: event.target.value });
  };
  waActiveHandleChange = (checked) => {
    this.setState({ waActive: checked });
  };
  waBodyHandleChange = (event) => {
    this.setState({ waBody: event.target.value });
  };
  notifyActiveHandleChange = (checked) => {
    this.setState({ notifyActive: checked });
  };
  notifyBodyHandleChange = (event) => {
    this.setState({ notifyBody: event.target.value });
  };
  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      const token = localStorage.getItem("token");

      if (this.props.resp) {
        axios
          .patch(
            Constants.api + "master/templates/" + this.props.resp.data.id,
            {
              "name": this.state.name,
              "emailActive": this.state.emailActive,
              "emailFrom": this.state.emailFrom,
              "emailSubject": this.state.emailSubject,
              "emailCC": this.state.emailCc,
              "emailBCC": this.state.emailBcc,
              "emailBody": this.state.emailBody,
              "smsActive": this.state.smsActive,
              "smsBody": this.statesmsBody,
              "waActive": this.state.waActive,
              "waBody": this.state.waBody,
              "notifyActive": this.state.notifyActive,
              "notifyBody": this.state.notifyBody,
              "programId": localStorage.getItem("masterprogramID"),
              "active": true
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              swal("Template Updated");
              this.setState({ create: 0 });
            }  else if (res.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          })
          .catch((err) => {
            //localStorage.clear();
            //    window.location = "/login"
          });;
      } else {
        axios
          .post(
            Constants.api + "master/templates",
            {
              "name": this.state.name,
              // "countryCode": this.state.countryCode,
              // "stateCode": this.state.stateCode,
              "district": this.state.selecteddistrict,
              "state": this.state.state,

              "latitude": this.state.latitude,
              "longitude": this.state.longitude
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 201) {
              swal("Template Added");

              this.setState({ createTemplate: 0 })
            }  else if (res.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          })
          .catch((err) => {
            //localStorage.clear();
            //    window.location = "/login"
          });;
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };

  render() {
    if (this.state.create == 0) {
      window.location = "/masters/geography/citys";
    }
    let state = this.state.state;

    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const addedContent = [...that.state.addedContent];
        const item = addedContent.splice(fromIndex, 1)[0];
        addedContent.splice(toIndex, 0, item);
        that.setState({ addedContent });
      },
      nodeSelector: "li",
      handleSelector: ".mcq_arrow",
    };
    return (
      <>

        <div id=" basic-info">

          <form onSubmit={this.handleFormSubmit}>
            <div class="card-body pt-0">

              <div class="row">
                <div class="col-md-12">
                  <label class="form-label mt-4">Name</label>
                  <input class="form-control" id="choices-skills" type="text" placeholder="" name="name" value={this.state.name} onChange={this.nameHandleChange} />
                  {this.validator.message(
                    "Template Name",
                    this.state.name,
                    "required"
                  )}
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label class="form-label mt-4">Email Active</label>

                  <Switch class="form-control" onChange={this.emailActiveHandleChange} checked={this.state.emailActive} />
                </div>
              </div>
              {this.state.emailActive ?
                <>
                  <div class="row">
                    <div class="col-md-6">
                      <label class="form-label mt-4">Email From</label>
                      <input class="form-control" id="choices-skills" type="text" placeholder="" name="name" value={this.state.name} onChange={this.nameHandleChange} />
                      {this.validator.message(
                        "Template Name",
                        this.state.name,
                        "required"
                      )}
                    </div>
                    <div class="col-md-6">
                      <label class="form-label mt-4">Email Subject</label>
                      <input class="form-control" id="choices-skills" type="text" placeholder="" name="name" value={this.state.name} onChange={this.nameHandleChange} />
                      {this.validator.message(
                        "Template Name",
                        this.state.name,
                        "required"
                      )}
                    </div>

                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <label class="form-label mt-4">Email CC</label>
                      <input class="form-control" id="choices-skills" type="text" placeholder="" name="name" value={this.state.emailCc} onChange={this.emailCcHandleChange} />
                      {this.validator.message(
                        "Template Name",
                        this.state.name,
                        "required"
                      )}
                    </div>
                    <div class="col-md-6">
                      <label class="form-label mt-4">Email BCC</label>
                      <input class="form-control" id="choices-skills" type="text" placeholder="" name="name" value={this.state.emailBcc} onChange={this.emailBccHandleChange} />
                      {this.validator.message(
                        "Template Name",
                        this.state.name,
                        "required"
                      )}
                    </div>

                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <label class="form-label mt-4">Email Body</label>
                      <textarea class="form-control" id="choices-skills" type="text" placeholder="" name="emailBody" onChange={this.emailBodyHandleChange}>{this.state.emailBody}</textarea>
                      {this.validator.message(
                        "Template Name",
                        this.state.name,
                        "required"
                      )}
                    </div>
                  </div> </> : ''}
              <div class="row">
                <div class="col-md-6">
                  <label class="form-label mt-4">SMS Active</label>

                  <Switch class="form-control" onChange={this.smsActiveHandleChange} checked={this.state.smsActive} />
                </div>
              </div>
              {this.state.smsActive ?
                <>

                  <div class="row">
                    <div class="col-md-12">
                      <label class="form-label mt-4">SMS Body</label>
                      <textarea class="form-control" id="choices-skills" type="text" placeholder="" name="emailBody" onChange={this.smsBodyHandleChange}>{this.state.smsBody}</textarea>
                      {this.validator.message(
                        "SMS Body",
                        this.state.name,
                        "required"
                      )}
                    </div>
                  </div> </> : ''}
              <div class="row">
                <div class="col-md-6">
                  <label class="form-label mt-4">Whatsapp Active</label>

                  <Switch class="form-control" onChange={this.waActiveHandleChange} checked={this.state.waActive} />
                </div>
              </div>
              {this.state.waActive ?
                <>

                  <div class="row">
                    <div class="col-md-12">
                      <label class="form-label mt-4">Whatsapp Body</label>
                      <textarea class="form-control" id="choices-skills" type="text" placeholder="" name="emailBody" onChange={this.waBodyHandleChange}>{this.state.waBody}</textarea>
                      {this.validator.message(
                        "Whatsapp Body",
                        this.state.name,
                        "required"
                      )}
                    </div>
                  </div> </> : ''}
              <div class="row">
                <div class="col-md-6">
                  <label class="form-label mt-4">Notification Active</label>

                  <Switch class="form-control" onChange={this.notifyActiveHandleChange} checked={this.state.notifyActive} />
                </div>
              </div>
              {this.state.notifyActive ?
                <>

                  <div class="row">
                    <div class="col-md-12">
                      <label class="form-label mt-4">Notification Body</label>
                      <textarea class="form-control" id="choices-skills" type="text" placeholder="" name="emailBody" onChange={this.notifyBodyHandleChange}>{this.state.notifyBody}</textarea>
                      {this.validator.message(
                        "Notify Body",
                        this.state.name,
                        "required"
                      )}
                    </div>
                  </div> </> : ''}
              <div class=" mt-4" id="delete">
                <div class="card-body">
                  <div class="d-flex align-items-center mb-sm-0 mb-4">
                    <div class="w-50">
                      {/* <p>Save State</p> */}
                      {/* <p class="text-sm mb-0">Once you delete your account, there is no going back. Please be certain.</p> */}
                    </div>
                    <div class="w-50 text-end">
                      <button class="btn bg-gradient-success mb-0 ms-2" type="submit" name="button" >Save </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>



      </>
    );
  }

}

export default CreateTemplates;
