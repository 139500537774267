import React, { Component } from "react";
import axios from "axios";
import swal from "sweetalert";
// import ShowTime from '../show-time/ShowTime.component';
import * as Constants from "../../Constants/index";
import SimpleReactValidator from "simple-react-validator";
import backgroundImage from "../../assets/background.png";

class Login extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
  }

  state = {
    email: "",
    password: "",
  };

  handleLoginSubmit = async (event) => {
    localStorage.setItem("language", "en");
    // localStorage.setItem("company", 0)
    // localStorage.setItem("program", 0)

    event.preventDefault();
    if (this.validator.allValid()) {
      try {
        const response = await axios
          .post(
            Constants.api + "auth/login-password",
            {
              mobile: this.state.email,
              password: this.state.password,
            },
            {
              headers: {
                appVersion: Constants.av,
                platform: Constants.pt,
                "Accept-Language": localStorage.getItem("language"),
                company: 1,
                program: 1,
              },
            }
          )
          .then((res) => {
            console.log("loginInfo", res.data);
            if (res.status === 200) {
              if (res.data.code === 10001) {
                swal(res.data.msg);
                localStorage.setItem("userid", res.data.output.user.id);
                localStorage.setItem("usertype", res.data.output.user.userType);
                localStorage.setItem("username", res.data.output.user.name);
                localStorage.setItem("useremail", res.data.output.user.email);
                localStorage.setItem("usermobile", res.data.output.user.mobile);
                localStorage.setItem("token", res.data.output.access.token);
                localStorage.setItem(
                  "refreshtoken",
                  res.data.output.refresh.token
                );

                window.location = "/dashboard";
              } else if (res.data.code === 16001) {
                swal(res.data.msg);
                this.setState({ otpShow: true });
              } else {
                swal(res.data.msg);
              }
            } else if (res.status == 403) {
              localStorage.removeItem("userid");
              window.location.href = "/login";
            } else {
              swal(res.data.msg);
            }
          });
      } catch (e) {
        swal("Something went wrong");
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };

  emailhandleChange = (event) => {
    this.setState({ email: event.target.value });
  };

  passwordhandleChange = (event) => {
    this.setState({ password: event.target.value });
  };

  render() {
    return (
      <>
        <main class="main-content  mt-0">
          <div class="page-header align-items-start min-vh-100">
            <span
              class="mask bg-gradient-dark "
              style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                height: "100vh",
                width: "100vw",
              }}
            ></span>
            <div class="container my-auto">
              <div class="row">
                <div class="col-lg-4 col-md-8 col-12 mx-auto">
                  <div class="card z-index-0 fadeIn3 fadeInBottom">
                    <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                      <div class="bg-gradient-info shadow-primary border-radius-lg py-3 pe-1">
                        <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">
                          Sign in
                        </h4>
                      </div>
                    </div>
                    <div class="card-body">
                      <form
                        role="form"
                        class="text-start"
                        onSubmit={this.handleLoginSubmit}
                      >
                        <div class="input-group input-group-outline my-3">
                          <input
                            class="form-control"
                            name="email"
                            placeholder="Mobile"
                            value={this.state.value}
                            onChange={this.emailhandleChange}
                          />
                        </div>
                        <div class="input-group input-group-outline mb-3">
                          <input
                            type="password"
                            class="form-control"
                            name="password"
                            placeholder="Password"
                            value={this.state.value}
                            onChange={this.passwordhandleChange}
                          />
                        </div>
                        {/* <div class="form-check form-switch d-flex align-items-center mb-3">
                          <input class="form-check-input" type="checkbox" id="rememberMe" checked />
                          <label class="form-check-label mb-0 ms-3" for="rememberMe">Remember me</label>
                        </div> */}
                        <div class="text-center">
                          <button
                            type="submit"
                            class="btn bg-gradient-info w-100 my-4 mb-2"
                          >
                            Sign in
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <footer class="footer position-absolute bottom-2 py-2 w-100">
              <div class="container">
                <div class="row align-items-center justify-content-lg-between">
                  <div class="col-12 col-md-12 my-auto">
                    <div class="copyright font-weight-normal text-center text-sm text-white text-lg-start">
                      ©{new Date().getFullYear()}, Powered by
                      <a
                        href="https://www.evolvebrands.com"
                        class="font-weight-bold text-white"
                        target="_blank"
                      >
                        {" "}
                        Evolve Brands
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </main>
      </>
    );
  }
}

export default Login;
