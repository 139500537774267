import React, { Component, useEffect, useState } from "react";
import * as Constants from "../../../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import Footer from "../../../footer/footer.component";
import Switch from "react-switch";
import SearchInput from "react-search-input";
import ReactDragListView from "react-drag-listview";

class CreateProducts extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      name: this.props.resp ? this.props.resp.data.output.name : "",
      sapCode: this.props.resp ? this.props.resp.data.output.sapCode : "",
      active: this.props.resp ? this.props.resp.data.output.active : false,
      tertiary: this.props.resp ? this.props.resp.data.output.tertiary : false,
      price: this.props.resp ? this.props.resp.data.output.price : 0,
      category: this.props.resp ? this.props.resp.data.output.category.split(',') : [],
      skuId: this.props.resp ? this.props.resp.data.output.skuId : "",
      image: this.props.resp ? this.props.resp.data.output.imageURL : "",

      skuDetail: this.props.resp ? this.props.resp.data.output.skuDetail : "",
      tertiary: this.props.resp ? this.props.resp.data.output.tertiary : false,
      point: this.props.resp ? this.props.resp.data.output.point : 0,

      sortBy: "name",
      limit: 1000,
      page: 1,
      searchTerm: "",

      search: "",
      create: 1,
      categorys: [],

    };
    this.activeHandleChange = this.activeHandleChange.bind(this);
    this.tertiaryHandleChange = this.tertiaryHandleChange.bind(this);
    this.searchUpdated = this.searchUpdated.bind(this);

  }
  componentDidMount() {
    const CategorysRequest = async () => {
      try {
        const token = localStorage.getItem("token");
        let url = "";
        const currentURL = window.location.pathname;
        const parts = currentURL.split("/");
        let id = parts.pop();
        this.state.search != ""
          ? (url =
            "program/child/category?" +
            "name=" +
            this.state.search +
            "&sortBy=" +
            this.state.sortBy +
            "&limit=" +
            this.state.limit +
            "&page=" +
            this.state.page)
          : (url =
            "program/child/category?sortBy=" +
            this.state.sortBy +
            "&limit=" +
            this.state.limit +
            "&page=" +
            this.state.page);
        const resp = await axios
          .get(Constants.api + url, {
            headers: {
              Authorization: `Bearer ${token}`, company: id, program: 0, appVersion: Constants.av,
              platform: Constants.pt
            },
          })
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              console.log(resp.data.output.results);
              this.setState({
                categorys: resp.data.output.results,
                totalResults: resp.data.output.totalResults,
              });
              console.log(this.state.categorys);
            }  else if (resp.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
            }
            //movie array
          }).catch((err) => {
            // window.location = "/login"
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    CategorysRequest();
  }
  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount()
    );
  };
  nameHandleChange = (event) => {
    this.setState({ name: event.target.value });
  };
  pointHandleChange = (event) => {
    this.setState({ point: event.target.value });
  };
  sapCodeHandleChange = (event) => {
    this.setState({ sapCode: event.target.value });
  };
  skuIdHandleChange = (event) => {
    this.setState({ skuId: event.target.value });
  };
  skuDetailHandleChange = (event) => {
    this.setState({ skuDetail: event.target.value });
  };
  priceHandleChange = (event) => {
    this.setState({ price: event.target.value });
  };
  categoryHandleChange = (event) => {
    this.setState({ category: event.target.value });
  };
  imageHandleChange = (event) => {
    this.setState({ image: event.target.value });
  };
  activeHandleChange(checked) {
    this.setState({ active: checked });
  }
  tertiaryHandleChange(checked) {
    this.setState({ tertiary: checked });
  }
  onFileChange = (event) => {

    // Update the state
    this.setState({ file: event.target.files[0] }, this.uploadImage);
    // Create an object of formData

    // Request made to the backend api
  };
  uploadImage = () => {
    let token = localStorage.getItem("token");

    const formData = new FormData();

    // Update the formData object

    formData.append("file", this.state.file);
    formData.append("type", 'Cproduct');


    axios
      .post(
        Constants.api + "uploader/upload",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
            platform: Constants.pt,
            appVersion: Constants.av,
            company: 0, program: 0
          },
        }
      )
      .then((res) => {
        if (res.status == 201) {

          this.setState({ image: res.data.output.url });

        }  else if (res.status == 403) {
          localStorage.clear();
          window.location.href = "/login";
        } else {
          // this.setState({alert:res.data.msg});
          swal("Something went wrong!");
        }
      })
  };
  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      const token = localStorage.getItem("token");
      let url = "";
      const currentURL = window.location.pathname;
      const parts = currentURL.split("/");
      let programid = parts.pop();
      programid = parseInt(programid);
      const pathSegments = window.location.pathname.split('/'); // Split the URL path by '/'
      let compid = pathSegments[pathSegments.length - 2]; // Get the last second segment

      if (this.props.resp) {

        axios
          .patch(
            Constants.api + "program/child/product/" + this.props.resp.data.output.id,
            {
              "name": this.state.name,
              "sapCode": this.state.sapCode,
              "skuId": this.state.skuId,
              "skuDetail": this.state.skuDetail,
              "price": this.state.price,
              "category": this.state.category.join(','),
              "tertiary": this.state.tertiary,
              "active": this.state.active,
              "point": this.state.point,
              "imageURL": this.state.image


            },
            {
              headers: {
                Authorization: `Bearer ${token}`, company: compid, program: programid, appVersion: Constants.av,
                platform: Constants.pt
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              swal("Product Updated");
              // this.setState({ create: 0 });
              this.onCancelTrigger(0);
            }  else if (res.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          })
          .catch((err) => {
            //localStorage.clear();
            //    window.location = "/login"
          });;
      } else {
        axios
          .post(
            Constants.api + "program/child/product",
            {
              "name": this.state.name,
              "sapCode": this.state.sapCode,
              "skuId": this.state.skuId,
              "skuDetail": this.state.skuDetail,
              "price": this.state.price,
              "category": this.state.category.join(','),
              "tertiary": this.state.tertiary,
              "active": this.state.active,
              "point": this.state.point,
              "imageURL": this.state.image


            },
            {
              headers: {
                Authorization: `Bearer ${token}`, company: compid, program: programid, appVersion: Constants.av,
                platform: Constants.pt
              },
            }
          )
          .then((res) => {
            if (res.status == 201) {
              swal("Product  Added");
              this.onCancelTrigger(0)
              this.setState({
                name: '',
                sapCode: '',
                skuId: '',
                image: '',
                skuDetail: '',
                price: '',
                category: '',
                tertiary: '',
                active: ''

              });
            }  else if (res.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          })
          .catch((err) => {
            //localStorage.clear();
            //    window.location = "/login"
          });;
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };
  addCategory(category) {
    this.setState({ searchTerm: "" })
    if (
      this.state.category.indexOf(category.name) == -1

    ) {
      this.state.category.push(category.name);
      this.state.categorys.splice(this.state.categorys.indexOf(category.name), 1);
    }
    console.log(this.state.categorys);
  }
  removeCategory(category) {
    this.state.category.splice(
      this.state.category.indexOf(category),
      1
    );
  }
  onCancelTrigger = (event) => {
    // Call the parent callback function
    this.props.setViewMode(event);
    event.preventDefault();
  }

  searchUpdated(term) {
    const token = localStorage.getItem("token");
    const currentURL = window.location.pathname;
    const parts = currentURL.split("/");
    let id = parts.pop();
    id = parseInt(id);
    if (term.length > 0) {
      axios
        .get(
          Constants.api + "company/cproduct/category?name=" +
          term +
          "&sortBy=" +
          this.state.sortBy +
          "&limit=10&page=" +
          this.state.page,
          {
            headers: {
              Authorization: `Bearer ${token}`, company: id, program: 0, appVersion: Constants.av,
              platform: Constants.pt
            },
          }
        )
        .then((res) => {
          if (res.status == 201 && res.data.msg != "error") {
            this.setState({ categorys: res.data.output.results });
            console.log(this.state.categorys);
          }  else if (res.status == 403) {
            localStorage.clear();
            window.location.href = "/login";
          }
        });
      this.setState({ searchTerm: term });
    } else {
      this.setState({
        categorys: [],
      });
    }
  }

  render() {
    if (this.state.create == 0) {
      this.setState({ create: 0 })
    }

    let search = this.state.search;
    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const category = [...that.state.category];
        const item = category.splice(fromIndex, 1)[0];
        category.splice(toIndex, 0, item);
        that.setState({ category });
      },
      nodeSelector: "li",
      handleSelector: ".move",
    };
    return (
      <>
        <main>
          <nav class="navbar navbar-main navbar-expand-lg position-sticky mt-4 top-1 px-0 mx-4 shadow-none border-radius-xl z-index-sticky" id="navbarBlur" data-scroll="true">
            <div class="container-fluid py-1 px-3">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">

                  <li class="breadcrumb-item text-sm"><a class="opacity-5 text-dark" href="javascript:;">Products</a></li>
                  <li class="breadcrumb-item text-sm text-dark active" aria-current="page">Add New</li>
                </ol>
              </nav>
              <div class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none ">
                {/* <a href="javascript:;" class="nav-link text-body p-0">
                  <div class="sidenav-toggler-inner">
                    <i class="sidenav-toggler-line"></i>
                    <i class="sidenav-toggler-line"></i>
                    <i class="sidenav-toggler-line"></i>
                  </div>
                </a> */}
              </div>

            </div>
          </nav>
          <div class="card mt-4" id="basic-info">
            <div class="card-header">
              <div class="col-md-4">
                <div class="avatar avatar-xl position-relative">
                  <img src={this.state.image} alt="bruce" class="w-100 rounded-circle shadow-sm addborder" />
                </div>
                <div class="file-input-container">
                  <label for="file-input">
                    <span class="camera-icon">&#128247;</span>
                  </label>
                  <input id="file-input" name="image" type="file" onChange={this.onFileChange}
                  />
                </div>
              </div>
              <h5>Product Info</h5>
            </div>

            <form onSubmit={this.handleFormSubmit}>
              <div class="card-body pt-0">

                <div class="row">
                  <div class="col-md-12">
                    <label class="form-label mt-4">Name</label>
                    <input class="form-control" id="choices-skills" type="text" placeholder="" name="name" value={this.state.name} onChange={this.nameHandleChange} />
                    {this.validator.message(
                      "Product Name",
                      this.state.name,
                      "required"
                    )}
                  </div>

                </div>

                <div class="row">

                </div>
                <div class="row">

                  <div class="col-md-4">
                    <label class="form-label mt-4">Sap Code</label>
                    <input class="form-control" id="choices-skills" type="text" placeholder="" name="sapCode" value={this.state.sapCode} onChange={this.sapCodeHandleChange} />

                  </div>

                  <div class="col-md-4">
                    <label class="form-label mt-4">Sku Detail</label>
                    <input class="form-control" id="choices-skills" type="text" placeholder="" name="skuDetail" value={this.state.skuDetail} onChange={this.skuDetailHandleChange} />

                  </div>

                  {/* <div class="col-md-4">
                    <label class="form-label mt-4">Price</label>
                    <input class="form-control" id="choices-skills" type="number" placeholder="" name="price" value={this.state.price} onChange={this.priceHandleChange} />

                  </div> */}

                  {/* <div class="col-md-3">
                    <label class="form-label mt-4">Search Categories</label>

                    <SearchInput
                      class="choices__list choices__list--single"
                      id="inputAddress"
                      onChange={this.searchUpdated}
                      value={this.state.searchTerm}

                      autoComplete={false}
                    />


                    {this.state.searchTerm.length > 0 ?
                      <ul class="dropdown-menu dropdown-menu-end p-2 me-sm-n4 show">

                        {this.state.categorys.map((category) => {
                          return (
                            <>

                              <li class="mb-2" onClick={() => this.addCategory(category)}>
                                <a class="dropdown-item border-radius-md" href="javascript:;">
                                  <div class="d-flex align-items-center py-1">
                                    <div class="ms-2">
                                      <h6 class="text-sm font-weight-normal my-auto">
                                        {category.name}
                                      </h6>
                                    </div>
                                  </div>
                                </a>
                              </li>

                            </>
                          );
                        })}
                      </ul>
                      : ''}

                  </div> */}
                  <div class="col-md-5">
                    {/* Listing of Added Categories */}
                    {this.state.category.length > 0 ? (
                      <>
                        <label class="form-label mt-4">Selected Categories</label>

                        <ReactDragListView {...dragProps}>
                          <ul style={{ listStyleType: "none" }}>
                            {this.state.category.map((category, index) => (
                              <li class="move" style={{ display: "inline" }} key={index}>
                                <span class="badge bg-gradient-primary choices__item is-highlighted ">{category}  <a
                                  href="#"

                                  onClick={() => this.removeCategory(category)}
                                >
                                  X
                                </a></span>&nbsp;
                              </li>
                            ))}
                          </ul>
                        </ReactDragListView>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <label class="form-label mt-4">Tertiary?</label>
                    <br />
                    <Switch class="form-control" onChange={this.tertiaryHandleChange} checked={this.state.tertiary} />
                  </div>

                  <div class="col-md-4">
                    <label class="form-label mt-4">Active?</label>
                    <br />
                    <Switch class="form-control" onChange={this.activeHandleChange} checked={this.state.active} />
                  </div>
                  <div class="col-md-4">
                    <label class="form-label mt-4">Points</label>
                    <input class="form-control" id="choices-skills" type="text" placeholder="" name="point" value={this.state.point} onChange={this.pointHandleChange} />
                    {this.validator.message(
                      "points",
                      this.state.point,
                      "required"
                    )}
                  </div>

                </div>

                <div class=" mt-4" id="delete">
                  <div class="card-body">
                    <div class="d-flex align-items-center mb-sm-0 mb-4">
                      <div class="w-50">
                        {/* <p>Save Country</p> */}
                        {/* <p class="text-sm mb-0">Once you delete your account, there is no going back. Please be certain.</p> */}
                      </div>
                      <div class="w-50 text-end">
                        <button class="btn btn-outline-secondary mb-3 mb-md-0 ms-auto" type="button" name="button" onClick={() => this.onCancelTrigger(0)}>Cancel</button>
                        <button class="btn bg-gradient-success mb-0 ms-2" type="submit" name="button" >Save </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

        </main >

      </>
    );
  }

}

export default CreateProducts;
