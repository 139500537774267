import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import axios from 'axios';
import * as Constants from "../../Constants/index"
import swal from 'sweetalert';
import ResponsivePagination from 'react-responsive-pagination';
import { saveAs } from 'file-saver';

export default function EnrollmentReport() {

  // const currentMonth = moment().startOf('month');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [results, setResults] = useState([])
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchRecord, setSearchRecord] = useState()
  const [limit] = useState('10')
  const [errors, setErrors] = useState({});


  // const dateString = fromDate;
  const formattedDate = moment(fromDate).format('YYYY-MM-DD HH:mm:ss');
  // const dateString2 = toDate;
  const formattedDate2 = moment(toDate).format('YYYY-MM-DD HH:mm:ss');
  const start = formattedDate.replace(/:/g, '%3A')
  const end = formattedDate2.replace(/:/g, '%3A')


  useEffect(() => {
    let token = localStorage.getItem('token');
    try {
      axios.get(Constants.api + `reports/users/list?userType=MEMBER&start=${fromDate === "" ? fromDate : start}&end=${toDate === '' ? toDate : end}&sortBy=name&sortAsc=true&limit=${limit}&page=${currentPage}`, {

        headers: {
          Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
          appVersion: Constants.av,
          platform: Constants.pt,
          company: 1,
          program: 1,
        }
      })
        .then((res) => {
          if (res.status === 201) {
            if (res.data.code === 10001 && res.data.output) {
              setResults(res.data.output.results)
              setTotalPages(res.data.output.totalPages);
              setSearchRecord()
            } else if (res.data.code === 12002 && res.data.output) {

              setSearchRecord(res.data.output.results)
              setTotalPages(res.data.output.totalPages);
            } else {
              swal({
                text: res.data.msg,
                icon: 'error',
                timer: 2000,
                buttons: false,
              })
            }
          }  else if (res.status == 403) {
            localStorage.clear();
            window.location.href = "/login";
          } else {
            swal({
              text: res.data.msg,
              icon: 'error',
              timer: 2000,
              buttons: false,
            })
          }
        })

    } catch (error) {
      console.log(error)
    }

  }, [currentPage])

  const download = () => {
    let token = localStorage.getItem('token');
    try {
      axios.get(Constants.api + `reports/download/userreport?userType=MEMBER&start=${fromDate === "" ? fromDate : start}&end=${toDate === '' ? toDate : end}`, {

        headers: {
          Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
          appVersion: Constants.av,
          platform: Constants.pt,
          company: 1,
          program: 1,
        },
        responseType: 'blob', // Set the responseType to 'blob'

      })
        .then((res) => {
          if (res.status === 200) {

            const fileData = new Blob([res.data], { type: 'text/csv' });
            saveAs(fileData, 'enrollmentReport.csv');
          }  else if (res.status == 403) {
            localStorage.clear();
            window.location.href = "/login";
          } else {
            swal({
              text: res.data.msg,
              // icon: 'error',
              timer: 2000,
              buttons: false,
            })
          }
        })
    } catch (error) {
      console.log(error)
    }
  }


  const validateForm = () => {
    let errors = {};

    if (!fromDate) {
      errors.fromDate = 'From Date is required';
    }

    if (!toDate) {
      errors.toDate = 'To Date is required';
    }

    return errors;
  };

  const handleSearch = async () => {

    let token = localStorage.getItem('token');
    setErrors({});
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return; // Don't proceed with the API call if there are validation errors
    }
    try {
      await axios.get(Constants.api + `reports/users/list?userType=MEMBER&start=${start}&end=${end}&sortBy=name&sortAsc=true&limit=${limit}&page=${currentPage}`, {

        headers: {
          Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
          appVersion: Constants.av,
          platform: Constants.pt,
          company: 1,
          program: 1,
        }
      })
        .then((res) => {
          if (res.status === 201) {
            if (res.data.code === 10001) {
              setResults(res.data.output.results)
              setTotalPages(res.data.output.totalPages);
              setSearchRecord()
              window.scrollTo({
                top: 370,
                behavior: 'smooth',
              });
            } else if (res.data.code === 12002) {

              setSearchRecord(res.data.output.results)
              setTotalPages(res.data.output.totalPages);
            }  else if (res.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
              swal({
                text: res.data.msg,
                icon: 'error',
                timer: 2000,
                buttons: false,
              })
            }
          } else {
            swal({
              text: res.data.msg,
              icon: 'error',
              timer: 2000,
              buttons: false,
            })
          }
        })

    } catch (error) {
      console.log(error)
    }

  }


  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };
  return (
    <>
      <section className="blog-area pt182">
        <div className="container">

          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="all-single-blog rpt">
                <div className="row clearfix mb-15" style={{ display: "none" }}>
                  {/* <div className="col-md-3 col-sm-12 col-xs-12">
                                        <div className="form-group has-float-label style-two">
                                            <label>Zonal <sup>*</sup> </label>
                                            <select className="form-control2" style={{ color: "Gray" }} >
                                                <option value="">All </option>
                                                <option>UP </option>
                                                <option>MP</option>
                                                <option>HP</option>

                                            </select>
                                        </div>
                                    </div> */}
                  {/* <div className="col-md-3 col-sm-12 col-xs-12">
                                        <div className="form-group has-float-label style-two">
                                            <label>State Head <sup>*</sup></label>
                                            <select className="form-control2" style={{ color: "Gray" }}>
                                                <option selected="selected" value="0">All </option>

                                            </select>
                                        </div>
                                    </div> */}

                  {/* <div className="col-md-3 col-sm-12 col-xs-12">
                                        <div className="form-group has-float-label style-two">
                                            <label>Sales Team <sup>*</sup> </label>
                                            <select className="form-control2" style={{ color: "Gray" }}>
                                                <option selected="selected" value="0">All </option>

                                            </select>
                                        </div>
                                    </div> */}


                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>From Date<sup>*</sup> </label>
                      <div>
                      </div>
                      <DatePicker
                        selected={fromDate}
                        onChange={(date) => setFromDate(date)}
                        dateFormat="dd-MM-yyyy HH:mm:ss"
                        className="form-control"
                        id="Fromdate"
                        showTimeSelect
                        timeFormat="HH:mm:ss"
                        // timeIntervals={1}
                        timeCaption="Time"
                        value={fromDate ? moment(fromDate).format('DD-MM-YYYY HH:mm:ss') : ''}
                        showYearDropdown
                        showMonthDropdown
                        autoComplete="off"
                        scrollableYearDropdown
                        maxDate={new Date()}
                      />
                      {/* <input type="text" className="form-control" id="Fromdate" /> */}
                    </div>
                    {errors.fromDate && (
                      <div className="error-message">{errors.fromDate}</div>
                    )}
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>To Date<sup>*</sup> </label>
                      <DatePicker
                        selected={toDate}
                        onChange={(date) => setToDate(date)}
                        dateFormat="dd-MM-yyyy HH:mm:ss"
                        className="form-control"
                        id="Fromdate"
                        showTimeSelect
                        timeFormat="HH:mm:ss"
                        // timeIntervals={1}
                        timeCaption="Time"
                        value={toDate ? moment(toDate).format('DD-MM-YYYY HH:mm:ss') : ''}
                        showYearDropdown
                        showMonthDropdown
                        autoComplete="off"
                        scrollableYearDropdown
                        maxDate={new Date()}
                      />
                      {/* <input type="text" className="form-control" id="Todate" /> */}
                    </div>
                    {errors.toDate && (
                      <div className="error-message">{errors.toDate}</div>
                    )}
                  </div>
                  <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                    <div className="form-group has-float-label style-two">
                      <label className="invisible" style={{ width: "100%" }}>hidden</label>
                      <input type="button" className="btn login_btn" value="Search" id="btnSearch" onClick={handleSearch} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="carrd1">
                      <div className="card-header card-header-warning">
                        <h4 className="card-title"> Enrollment Report
                          {searchRecord === null ? '' :
                            <Link to="#" class="btn btn-primary pull-right" onClick={download}><i class="fa fa-file-excel-o"></i> Export</Link>}
                        </h4>
                      </div>

                      <div className="card-body table-responsive">
                        <table className="table table-striped mb_01" id="table-to-xls">
                          <thead>
                            <tr>
                              <th>S.No.</th>
                              <th>Zonal Head	</th>
                              <th>State Head	</th>
                              <th>Sales Team	</th>
                              <th>Plumber Name</th>
                              <th>Mobile Number</th>
                              <th>Email ID</th>
                              {/* <th>DOB	</th> */}
                              <th>Mobile No. Linked with Aadhar</th>
                              <th>Aadhar Front Image</th>
                              <th>Aadhar back Image</th>
                              <th>Address1	</th>
                              <th>Address2	</th>
                              <th>Address3</th>
                              <th>City	</th>
                              <th>State	</th>
                              <th>Date of Registartion</th>
                              <th>Registration Type</th>
                              <th>Status</th>
                              <th>Reason for Rejection</th>
                              <th>Admin Approval Date</th>
                              <th>Sales/Marketing Team Name</th>
                              <th>Sales/Marketing Team Approval Date</th>
                              {/* <th>Bonous</th> */}
                              <th>Total Earned Stars	</th>
                              <th>Total Redeemed Stars</th>
                              <th>First Scan Date</th>
                            </tr>
                          </thead>
                          {searchRecord === null ?
                            <tbody>
                              <tr>
                                <td colSpan="11" style={{ textAlign: 'center', fontSize: '16px' }}>No record found!</td>
                              </tr>
                            </tbody> :
                            <tbody>
                              {results && results.map((item, index) => {
                                // const reason = item.approval ? item.approval.reason : null;
                                const dateOfRegistration = item.dateOfRegistration ? moment(item.dateOfRegistration).format('DD-MM-YYYY') : ''
                                const adminApprovalDate = item.adminApprovalDate ? moment(item.adminApprovalDate).format('DD-MM-YYYY') : ''
                                const salesApprovalDate = item.salesMarketingTeamApprovalDate ? moment(item.salesMarketingTeamApprovalDate).format('DD-MM-YYYY') : ''
                                const serialNumber = (currentPage - 1) * limit + index + 1;
                                const isAadharFrontUrlAvailable = item.aadharFrontImage && item.aadharFrontImage.trim() !== "";
                                const isAadharBackUrlAvailable = item.aadharBackImage && item.aadharBackImage.trim() !== "";
                                const firstScanDate = item.firstScanDate ? moment(item.firstScanDate).format('DD-MM-YYYY') : ''

                                return (
                                  <>
                                    <tr key={index}>
                                      <td>{serialNumber}</td>
                                      <td>{item.zonalHead}</td>
                                      <td>{item.stateHead}</td>
                                      <td>{item.salesTeamName}</td>
                                      <td>{item.plumberName}</td>
                                      <td>{item.mobileNumber}</td>
                                      <td>{item.emailId}</td>
                                      {/* <td>{item.dob}</td> */}
                                      <td>{item.mobileNoInAadhar}</td>
                                      <td>{isAadharFrontUrlAvailable ? (
                                        <Link to={item.aadharFrontImage} target="_blank" className="select">View</Link>) : (
                                        "N/A"
                                      )}</td>
                                      <td>{isAadharBackUrlAvailable ? (
                                        <Link to={item.aadharBackImage} target="_blank" className="select">View</Link>) : (
                                        "N/A"
                                      )}</td>
                                      <td>{item.address1}</td>
                                      <td>{item.address2}</td>
                                      <td>{item.address3}</td>
                                      <td>{item.city}</td>
                                      <td>{item.state}</td>
                                      <td>{dateOfRegistration}</td>
                                      <td>{item.registrationype}</td>
                                      <td>{item.status}</td>
                                      <td>{item.reasonOfRejection}</td>
                                      <td>{adminApprovalDate}</td>
                                      <td>{item.salesMarketingTeamName}</td>
                                      <td>{salesApprovalDate}</td>
                                      {/* <td></td> */}
                                      <td>{item.totalEarnedStars}</td>
                                      <td>{item.totalRedeemedStar}</td>
                                      <td>{firstScanDate}</td>

                                    </tr>
                                  </>
                                )
                              })}
                            </tbody>}
                        </table>
                        {totalPages > 1 && (
                          <ResponsivePagination
                            current={currentPage}
                            total={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}
