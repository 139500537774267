import React, { Component, useEffect, useState } from "react";
import * as Constants from "../../Constants/index"
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import Switch from "react-switch";
import ReactDragListView from "react-drag-listview";

class CreateCompanyLocations extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {

      locationName: this.props.resp ? this.props.resp.data.output.name : "",
      locationGst: this.props.resp ? this.props.resp.data.output.gstNumber : "",
      locationaddress1: this.props.resp ? this.props.resp.data.output.addressModel.address1 : "",
      locationaddress2: this.props.resp ? this.props.resp.data.output.addressModel.address2 : "",
      locationaddress3: this.props.resp ? this.props.resp.data.output.addressModel.address3 : "",
      locationcity: this.props.resp ? this.props.resp.data.output.addressModel.city : "",
      locationstate: this.props.resp ? this.props.resp.data.output.addressModel.state : "",
      locationcountry: this.props.resp ? this.props.resp.data.output.addressModel.country : "",

      sortBy: "name",
      limit: 1000,
      page: 1,
      searchTerm: "",

      search: "",
      industries: [],
      create: 1,
    };

  }

  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount()
    );
  };
  nameHandleChange = (event) => {
    this.setState({ name: event.target.value });
  };
  yearIncorporationHandleChange = (event) => {
    this.setState({ yearIncorporation: event.target.value });
  };
  logoHandleChange = (event) => {
    this.setState({ logo: event.target.value });
  };
  brandTaglineHandleChange = (event) => {
    this.setState({ brandTagline: event.target.value });
  };
  panHandleChange = (event) => {
    this.setState({ pan: event.target.value });
  };
  coiHandleChange = (event) => {
    this.setState({ coi: event.target.value });
  };
  industryHandleChange = (event) => {
    this.setState({ industry: event.target.value });
  };




  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      const currentURL = window.location.pathname;
      const parts = currentURL.split("/");
      let id = parts.pop();
      id = parseInt(id);
      const token = localStorage.getItem("token");

      if (this.props.resp) {
        axios
          .patch(
            Constants.api + "company/companyLocation/" + this.props.resp.data.output.id,
            {
              "name": this.state.locationName,
              "gstNumber": this.state.locationGst,
              "addressModel": {
                "address1": this.state.locationaddress1,
                "address2": this.state.locationaddress2,
                "address3": this.state.locationaddress3,
                "city": this.state.locationcity,
                "state": this.state.locationstate,
                "pincode": this.state.locationpincode,
                "country": this.state.locationcountry
              },
            },
            // "addresses": this.state.addresses,
            // "locations": this.state.locations


            {
              headers: {
                Authorization: `Bearer ${token}`, company: id, program: 0, appVersion: Constants.av,
                platform: Constants.pt
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              swal("Company Updated");
              // this.setState({ create: 0 });
              this.onCancelTrigger(0)
            }  else if (res.status == 403) {
              localStorage.removeItem("userid");
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          })
          .catch((err) => {
            // localStorage.clear();
            // window.location = "/login"
          });;
      } else {
        axios
          .post(
            Constants.api + "company/companyLocation",
            {
              "name": this.state.locationName,
              "gstNumber": this.state.locationGst,
              "addressModel": {
                "address1": this.state.locationaddress1,
                "address2": this.state.locationaddress2,
                "address3": this.state.locationaddress3,
                "city": this.state.locationcity,
                "state": this.state.locationstate,
                "pincode": this.state.locationpincode,
                "country": this.state.locationcountry
              },
            },
            {
              headers: {
                Authorization: `Bearer ${token}`, company: id, program: 0, appVersion: Constants.av,
                platform: Constants.pt
              },
            }
          )
          .then((res) => {
            if (res.status == 201) {
              swal("Company Location Added");
              // this.setState({ create: 0 });
              this.onCancelTrigger(0)

            }  else if (res.status == 403) {
              localStorage.removeItem("userid");
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          })
          .catch((err) => {
            // localStorage.clear();
            //window.location = "/login"
          });;
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };

  onCancelTrigger = (event) => {
    this.props.setViewMode(event);
    event.preventDefault();
  }

  render() {

    const handlelocationNameChange = (e) => {
      this.setState({ locationName: e.target.value });
    }
    const handlelocationGstChange = (e) => {
      this.setState({ locationGst: e.target.value });
    }
    const handlelocationaddress1Change = (e) => {
      this.setState({ locationaddress1: e.target.value });
    }
    const handlelocationaddress2Change = (e) => {
      this.setState({ locationaddress2: e.target.value });
    }
    const handlelocationaddress3Change = (e) => {
      this.setState({ locationaddress3: e.target.value });
    }
    const handlelocationcityChange = (e) => {
      this.setState({ locationcity: e.target.value });
    }

    const handlelocationstateChange = (e) => {
      this.setState({ locationstate: e.target.value });
    }
    const handlelocationpincodeChange = (e) => {
      this.setState({ locationpincode: e.target.value });
    }
    const handlelocationcountryChange = (e) => {
      this.setState({ locationcountry: e.target.value });
    }



    let search = this.state.search;

    return (
      <>

        <main>
          <div class="container-fluid my-3 py-3">
            <div class="row mb-5">

              <div class="col-lg-12 mt-lg-0 mt-4">
                <div class="card card-body" id="profile">
                  <div class="row justify-content-center align-items-center">
                    <div class="col-sm-auto col-4">

                    </div>
                    <div class="col-sm-auto col-8 my-auto">
                      <div class="h-100">
                        <h5 class="mb-1 font-weight-bolder">
                          Company Location
                        </h5>

                      </div>
                    </div>
                    <div class="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
                      {/* <label class="form-check-label mb-0">
                        <small id="profileVisibility">
                          Switch to invisible
                        </small>
                      </label>
                      <div class="form-check form-switch ms-2 my-auto">
                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault23" checked onchange="visible()" />
                      </div> */}
                    </div>
                  </div>
                </div>
                <form >
                  <div class="card mt-4" id="basic-info">
                    <div class="card-header">
                      <h5>Location Info</h5>
                    </div>
                    <div class="card-body pt-0">
                      <div class="row">
                        <div class="col-6">
                          <div class="input-group input-group-static">
                            <label> Name</label>
                            <input type="text" class="form-control" value={this.state.locationName} onChange={handlelocationNameChange} placeholder="Evolve" />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="input-group input-group-static">
                            <label>GST</label>
                            <input type="text" class="form-control" value={this.state.locationGst} onChange={handlelocationGstChange} />
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div class="card mt-4" id="password">
                    <div class="card-header">
                      <h5>Address</h5>
                    </div>
                    <div class="card-body pt-0">

                      <div class="row">

                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          Address Line 1
                        </div>
                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          <input type="text" maxlength="30" name="locationaddress1" value={this.state.locationaddress1} class="form-control" onChange={(e) =>
                            handlelocationaddress1Change(e)
                          } />
                        </div>

                      </div>

                      <div class="row">

                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          Address Line 2

                        </div>
                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          <input type="text" maxlength="30" name="locationaddress2" value={this.state.locationaddress2} class="form-control" onChange={(e) =>
                            handlelocationaddress2Change(e)
                          } />
                        </div>

                      </div>

                      <div class="row">

                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          Address Line 3
                        </div>
                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          <input type="text" maxlength="30" name="locationaddress3" value={this.state.locationaddress3} class="form-control" onChange={(e) =>
                            handlelocationaddress3Change(e)
                          } />
                        </div>
                      </div>
                      <div class="row">
                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          City
                        </div>
                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          <input type="text" name="locationcity" value={this.state.locationcity} class="form-control" onChange={(e) =>
                            handlelocationcityChange(e)
                          } />
                        </div>
                      </div>

                      <div class="row">
                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          State
                        </div>
                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          <input type="text" name="locationstate" value={this.state.locationstate} class="form-control" onChange={(e) =>
                            handlelocationstateChange(e)
                          } />
                        </div>
                      </div>
                      <div class="row">
                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          Pincode
                        </div>
                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          <input type="text" name="locationpincode" maxlength="6" value={this.state.locationpincode} class="form-control" onChange={(e) =>
                            handlelocationpincodeChange(e)
                          } />
                        </div>
                      </div>
                      <div class="row">
                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          Country
                        </div>
                        <div class="input-group  col-sm-4 input-group-outline my-4">
                          <input type="text" name="locationcountry" value={this.state.locationcountry} class="form-control" onChange={(e) =>
                            handlelocationcountryChange(e)
                          } />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card mt-4" id="delete">
                    <div class="card-body">
                      <div class="d-flex align-items-center mb-sm-0 mb-4">
                        <div class="w-50">
                          <h5>Save Company Location</h5> 
                        </div>
                        <div class="w-50 text-end">
                          <button class="btn btn-outline-secondary mb-3 mb-md-0 ms-auto" type="button" name="button" onClick={() => this.onCancelTrigger(0)}>Cancel</button>
                          <button class="btn bg-gradient-success mb-0 ms-2" type="submit" name="button" onClick={this.handleFormSubmit} >Save Company Location</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </main >
      </>
    );
  }

}

export default CreateCompanyLocations;
