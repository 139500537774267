import React, { Component, useEffect, useState } from "react";
import * as Constants from "../../Constants/index"
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import Switch from "react-switch";
import ReactDragListView from "react-drag-listview";

class CreateTestimonials extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {

      name: this.props.resp ? this.props.resp.data.output.name : "",
      link: this.props.resp ? this.props.resp.data.output.link : "",
      active: this.props.resp ? this.props.resp.data.output.active : 0,
      description: this.props.resp ? this.props.resp.data.output.description : "",
      contentType: this.props.resp ? this.props.resp.data.output.contentType : "",
      dataType: this.props.resp ? this.props.resp.data.output.dataType : "",


      sortBy: "name",
      limit: 1000,
      page: 1,
      searchTerm: "",

      search: "",
      industries: [],
      create: 1,
    };
    this.activeHandleChange = this.activeHandleChange.bind(this);

  }

  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount()
    );
  };
  nameHandleChange = (event) => {
    this.setState({ name: event.target.value });
  };
  linkHandleChange = (event) => {
    this.setState({ link: event.target.value });
  };
  contentTypeHandleChange = (event) => {
    this.setState({ contentType: event.target.value });
  };
  dataTypeHandleChange = (event) => {
    this.setState({ dataType: event.target.value });
  };
  descriptionHandleChange = (event) => {
    this.setState({ description: event.target.value });
  };


  activeHandleChange(checked) {
    this.setState({ active: checked });
  }


  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      const currentURL = window.location.pathname;
      const parts = currentURL.split("/");
      let id = parts.pop();
      id = parseInt(id);
      const token = localStorage.getItem("token");

      if (this.props.resp) {
        axios
          .patch(
            Constants.api + "program/testimonial/" + this.props.resp.data.output.id,
            {
              "name": this.state.name,
              "link": this.state.link,
              "description": this.state.description,
              "contentType": this.state.contentType,
              "dataType": this.state.dataType,

              "active": this.state.active

            },
            // "addresses": this.state.addresses,
            // "locations": this.state.locations


            {
              headers: {
                Authorization: `Bearer ${token}`, company: id, program: 1, appVersion: Constants.av,
                platform: Constants.pt
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              swal("Testimonial Updated");
              // this.setState({ create: 0 });
              this.onCancelTrigger(0)
            } else if (res.status == 403) {
              localStorage.removeItem("userid");
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          })
          .catch((err) => {
            // localStorage.clear();
            // window.location = "/login"
          });;
      } else {
        axios
          .post(
            Constants.api + "program/testimonial",
            {
              "name": this.state.name,
              "link": this.state.link,
              "description": this.state.description,
              "contentType": this.state.contentType,
              "dataType": this.state.dataType,
              "active": this.state.active

            },
            {
              headers: {
                Authorization: `Bearer ${token}`, company: id, program: 1, appVersion: Constants.av,
                platform: Constants.pt
              },
            }
          )
          .then((res) => {
            if (res.status == 201) {
              swal("Testimonial Added");
              // this.setState({ create: 0 });
              this.onCancelTrigger(0)

            } else if (res.status == 403) {
              localStorage.removeItem("userid");
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          })
          .catch((err) => {
            // localStorage.clear();
            //window.location = "/login"
          });;
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };

  onCancelTrigger = (event) => {
    this.props.setViewMode(event);
    event.preventDefault();
  }

  render() {



    let search = this.state.search;

    return (
      <>

        <main>
          <div class="container-fluid my-3 py-3">
            <div class="row mb-5">

              <div class="col-lg-12 mt-lg-0 mt-4">
                <div class="card card-body" id="profile">
                  <div class="row justify-content-center align-items-center">
                    <div class="col-sm-auto col-4">

                    </div>
                    <div class="col-sm-auto col-8 my-auto">
                      <div class="h-100">
                        <h5 class="mb-1 font-weight-bolder">
                          Testimonials
                        </h5>

                      </div>
                    </div>
                    <div class="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
                      {/* <label class="form-check-label mb-0">
                        <small id="profileVisibility">
                          Switch to invisible
                        </small>
                      </label>
                      <div class="form-check form-switch ms-2 my-auto">
                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault23" checked onchange="visible()" />
                      </div> */}
                    </div>
                  </div>
                </div>
                <form >
                  <div class="card mt-4" id="basic-info">
                    <div class="card-header">
                      {/* <h5>Location Info</h5> */}
                    </div>
                    <div class="card-body pt-0">
                      <div class="row">
                        <div class="col-6">
                          <div class="input-group input-group-static">
                            <label> Name</label>
                            <input type="text" class="form-control" value={this.state.name} onChange={this.nameHandleChange} />
                          </div>
                        </div>
                        {this.validator.message(
                          "name",
                          this.state.name,
                          "required"
                        )}
                        <div class="col-6">
                          <div class="input-group input-group-static">
                            <label>Link</label>
                            <input type="text" class="form-control" value={this.state.link} onChange={this.linkHandleChange} />
                          </div>
                        </div>
                      </div>
                      {this.validator.message(
                        "link",
                        this.state.link,
                        "required"
                      )}
                      <div class="row">
                        <div class="col-6">
                          <div class="input-group input-group-static">
                            <label>Content Type</label>
                            <select class="form-control" name="contentType" value={this.state.contentType} onChange={this.contentTypeHandleChange} >
                              <option value="testimonial">Testimonial</option>
                              <option value="video">Video</option>

                            </select>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="input-group input-group-static">
                            <label>Data Type</label>
                            <select class="form-control" name="dataType" value={this.state.dataType} onChange={this.dataTypeHandleChange} >
                              <option value="image">image</option>
                              <option value="video">video</option>

                            </select>
                          </div>
                        </div>

                        <div class="col-6">
                          <div class="input-group input-group-static">
                            <label>Link</label>
                            <input type="text" class="form-control" value={this.state.link} onChange={this.linkHandleChange} />
                          </div>
                        </div>

                        <div class="col-md-4">
                          <label class="form-label mt-4">Active?</label>
                          <br />
                          <Switch class="form-control" onChange={this.activeHandleChange} checked={this.state.active} />
                        </div>

                      </div>

                    </div>
                  </div>


                  <div class="card mt-4" id="delete">
                    <div class="card-body">
                      <div class="d-flex align-items-center mb-sm-0 mb-4">
                        <div class="w-50">
                          <h5>Save Testimonial</h5>
                        </div>
                        <div class="w-50 text-end">
                          <button class="btn btn-outline-secondary mb-3 mb-md-0 ms-auto" type="button" name="button" onClick={() => this.onCancelTrigger(0)}>Cancel</button>
                          <button class="btn bg-gradient-success mb-0 ms-2" type="submit" name="button" onClick={this.handleFormSubmit} >Save Testimonials</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div >

          </div >
        </main >
      </>
    );
  }

}

export default CreateTestimonials;
