import { React, Component } from "react";
import Navbar from "components/navbar/navbar.component";

class Footer extends Component {


  render() {

    return (
      <>

        <footer class="footer py-4  ">
          <div class="container-fluid">
            <div class="row align-items-center justify-content-lg-between">
              <div class="col-lg-6 mb-lg-0 mb-4">
                <div class="copyright text-center text-sm text-muted text-lg-start">
                  ©
                  {document.write(new Date().getFullYear())}
                  All Rights Reserved
                  {/* Developed by
                  <a href="https://www.wemonde.com" class="font-weight-bold" target="_blank">Wemonde</a> */}
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Navbar;
