import React, { Component, useEffect, useState } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import Switch from "react-switch";
import SearchInput from "react-search-input";
import ReactDragListView from "react-drag-listview";

class CreateProgramBranch extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      name: this.props.resp ? this.props.resp.data.output.name : "",
      regions: [],
      citys: this.props.resp ? this.props.resp.data.output.region.citiesList : [],
      selectedCitys: this.props.resp ? this.props.resp.data.output.citiesList : [],
      search: '',
      sortBy: "name",
      limit: "100000",
      page: 1,
      region: this.props.resp ? this.props.resp.data.output.region : {},
      selectedRegion: this.props.resp ? this.props.resp.data.output.region.id : "",

    };

  }
  componentDidMount() {
    const CategorysRequest = async () => {
      try {
        const token = localStorage.getItem("token");
        const currentURL = window.location.pathname;
        const parts = currentURL.split("/");
        let programid = parts.pop();
        programid = parseInt(programid);
        const pathSegments = window.location.pathname.split('/'); // Split the URL path by '/'
        let compid = pathSegments[pathSegments.length - 2];
        let url = "";
        this.state.search != ""
          ? (url =
            "program/child/region/region?" +
            "name=" +
            this.state.search +
            "&sortBy=" +
            this.state.sortBy +
            "&limit=" +
            this.state.limit +
            "&page=" +
            this.state.page)
          : (url =
            "program/child/region/region?sortBy=" +
            this.state.sortBy +
            "&limit=" +
            this.state.limit +
            "&page=" +
            this.state.page);
        const resp = await axios
          .get(Constants.api + url, {
            headers: { Authorization: `Bearer ${token}`, company: compid, program: programid, platform: Constants.pt, appVersion: Constants.av },
          })
          .then((resp) => {
            //  casetype array

            if (resp.status == 201 && resp.data.result != "error") {
              console.log(resp.data.output.results);
              this.setState({
                regions: resp.data.output.results,
                totalResults: resp.data.output.totalResults,
              });
              console.log(this.state.Categorys);
            }  else if (resp.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
            }
            //movie array
          }).catch((err) => {
            // window.location = "/login"
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    CategorysRequest();
  }
  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount()
    );
  };

  nameHandleChange = (event) => {
    this.setState({ name: event.target.value });
  };

  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      const token = localStorage.getItem("token");
      const currentURL = window.location.pathname;
      const parts = currentURL.split("/");
      let programid = parts.pop();
      programid = parseInt(programid);
      const pathSegments = window.location.pathname.split('/'); // Split the URL path by '/'
      let compid = pathSegments[pathSegments.length - 2];
      if (this.props.resp) {
        axios
          .patch(
            Constants.api + "program/child/region/branch/" + this.props.resp.data.output.id,
            {
              name: this.state.name,
              citiesList: this.state.selectedCitys,
              region: this.state.region

            },
            {
              headers: {
                Authorization: `Bearer ${token}`, company: compid, program: programid, appVersion: Constants.av,
                platform: Constants.pt
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              swal("Branch Updated");
              this.setState({ create: 0 });
            }  else if (res.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          })
          .catch((err) => {
            //localStorage.clear();
            //    window.location = "/login"
          });;
      } else {
        axios
          .post(
            Constants.api + "program/child/region/branch/",
            {
              name: this.state.name,
              citiesList: this.state.selectedCitys,
              region: this.state.region

            },
            {
              headers: {
                Authorization: `Bearer ${token}`, company: compid, program: programid, appVersion: Constants.av,
                platform: Constants.pt
              },
            }
          )
          .then((res) => {
            if (res.status == 201) {
              swal("Branch Added");

              this.setState({ createTemplate: 0 })
            }  else if (res.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          })
          .catch((err) => {
            //localStorage.clear();
            //    window.location = "/login"
          });;
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };





  render() {
    if (this.state.create == 0) {
      window.location.reload()
    }

    let regionHandlechange = (region) => {
      console.log(region);
      this.setState({ region, selectedRegion: region.id });

      let citys = region.cities.split(',')
      console.log(citys)
      this.setState({
        citys
      })
    };
    const handleCityChange = (event, cityName) => {
      let selectedCitys = this.state.selectedCitys;
      const index = selectedCitys.indexOf(cityName);
      if (index == -1) {
        selectedCitys.push(cityName)

      } else {
        let selectedCitys = selectedCitys.filter((item) => item !== cityName);

      }
      this.setState({
        selectedCitys: selectedCitys
      })
    };
    let selectedCitys = this.state.selectedCitys;
    let region = this.state.region;
    let selectedRegion = this.state.selectedRegion;

    return (
      <>
        <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
          <nav class="navbar navbar-main navbar-expand-lg position-sticky mt-4 top-1 px-0 mx-4 shadow-none border-radius-xl z-index-sticky" id="navbarBlur" data-scroll="true">
            <div class="container-fluid py-1 px-3">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">

                  <li class="breadcrumb-item text-sm"><a class="opacity-5 text-dark" href="javascript:;"> Program Region</a></li>
                  <li class="breadcrumb-item text-sm text-dark active" aria-current="page">Add New</li>
                </ol>
              </nav>
              <div class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none ">
                <a href="javascript:;" class="nav-link text-body p-0">
                  <div class="sidenav-toggler-inner">
                    <i class="sidenav-toggler-line"></i>
                    <i class="sidenav-toggler-line"></i>
                    <i class="sidenav-toggler-line"></i>
                  </div>
                </a>
              </div>

            </div>
          </nav>
          <div class="card mt-4" id="basic-info">
            <div class="card-header">
              <div class="col-md-8">

              </div>
              <h5>Program Branch</h5>
            </div>
            <form onSubmit={this.handleFormSubmit}>
              <div class="card-body pt-0">

                <div class="row">
                  <div class="col-md-12">
                    <label class="form-label mt-4">Name</label>
                    <input class="form-control" id="choices-skills" type="text" placeholder="" name="name" value={this.state.name} onChange={this.nameHandleChange} />
                    {this.validator.message(
                      "Branch Name",
                      this.state.name,
                      "required"
                    )}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <label class="form-label mt-4">Region</label>
                    <div class="mx-2">{
                      this.state.regions.map(function (item, i) {
                        let checked = false;
                        if (selectedRegion == item.id)
                          checked = true;
                        return (<div class="col-sm-3"> <label >
                          <input
                            type="radio"
                            checked={checked}
                            onClick={() => regionHandlechange(item)}
                          />&nbsp;
                          {item.name}
                        </label></div>)

                      })}

                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <label class="form-label mt-4">Cities</label>
                    <div class="mx-4">
                      {this.state.citys.map(function (item, i) {
                        return (<div class="col-sm-3"> <label >
                          <input
                            type="checkbox"
                            checked={selectedCitys.includes(item)}
                            onChange={(event) => handleCityChange(event, item)}
                          />&nbsp;
                          {item}
                        </label></div>)

                      })}

                    </div>
                  </div>
                </div>
              </div>
              <div class=" mt-4" id="delete">
                <div class="card-body">
                  <div class="d-flex align-items-center mb-sm-0 mb-4">
                    <div class="w-50">
                      {/* <p>Save State</p> */}
                      {/* <p class="text-sm mb-0">Once you delete your account, there is no going back. Please be certain.</p> */}
                    </div>
                    <div class="w-50 text-end">
                      <button class="btn bg-gradient-success mb-0 ms-2" type="submit" name="button" >Save </button>
                    </div>
                  </div>
                </div>
              </div>

            </form>
          </div>

        </main >

      </>
    );
  }

}

export default CreateProgramBranch;
