import React, { Component } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { UncontrolledCollapse, Button, CardBody, Card } from "reactstrap";
import axios from "axios";
import * as Constants from "../../Constants/index";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";

class Navbar extends Component {
  constructor(props) {
    if (localStorage.getItem("userid") == null)
      localStorage.setItem("userid", 0);
    super(props);
    this.state = {
      cities: [],
      lat: 0.0,
      lng: 0.0,
      isNavOpen: false,
    };
  }

  componentDidMount() {
    const profileRequest = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios
          .post(
            Constants.api + "user/manage/" + localStorage.getItem("userid"),
            {
              headers: {
                Authorization: `Bearer ${token}`,

                appVersion: Constants.av,
                platform: Constants.pt,
                "Accept-Language": localStorage.getItem("language"),
                company: 0,
                program: 0,
              },
            }
          )
          .then((res) => {});
      } catch (err) {
        try {
          const response = await axios
            .post(
              Constants.api + "auth/refresh-tokens",

              {
                refreshToken: localStorage.getItem("refreshtoken"),
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,

                  appVersion: Constants.av,
                  platform: Constants.pt,
                  "Accept-Language": localStorage.getItem("language"),
                  company: 0,
                  program: 0,
                },
              }
            )
            .then((res) => {
              if (res.status == 200) {
                // console.log(res);
                localStorage.setItem("token", res.data.output.access.token);
                localStorage.setItem(
                  "refreshtoken",
                  res.data.output.refresh.token
                );
                window.location.href = "/";
              } else if (res.status == 403) {
                localStorage.clear();
                window.location.href = "/login";
              }
            });
        } catch (err) {
          //localStorage.clear();
          //  window.location.href = "/login";
        }
      }
    };

    if (localStorage.getItem("userid")) {
      // profileRequest();
      // Initial call on component mount
      // Call fetchData every minute
      // this.interval = setInterval(profileRequest, 60000);
    }
  }

  componentWillUnmount() {
    // Clear interval on component unmount
    clearInterval(this.interval);
  }

  handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  getMyLocation = (e) => {
    let location = null;
    let latitude = null;
    let longitude = null;
    if (window.navigator && window.navigator.geolocation) {
      location = window.navigator.geolocation;
    }
    if (location) {
      location.getCurrentPosition(function (position) {
        latitude = position.coords.latitude;
        longitude = position.coords.longitude;
      });
    }
    this.setState({ lat: latitude, lng: longitude });
  };

  toggleNavbar = () => {
    this.setState((prevState) => ({ isNavOpen: !prevState.isNavOpen }));
  };

  render() {
    if (localStorage.getItem("navbarMenu") === null) {
      localStorage.setItem("navbarMenu", "");
    }
    let today = new Date();
    // console.log(JSON.stringify(today));
    // console.log(localStorage.getItem("tokenexpiry"));
    if (JSON.stringify(today) >= localStorage.getItem("tokenexpiry"))
      this.handleLogout();

    const splitLocationfirst = window.location.pathname.split("/")[1];
    const splitLocationsecond = window.location.pathname.split("/")[2];
    const splitLocationlast = window.location.pathname.split("/").pop();

    return (
      <>
        <aside
          class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3   bg-gradient-dark"
          id="sidenav-main"
        >
          <div class="sidenav-header">
            <i
              class="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
              aria-hidden="true"
              id="iconSidenav"
            ></i>
            <a class="navbar-brand m-1" href="# ">
              <img
                src={require("../../assets/favicon.ico")}
                class="navbar-brand-img h-100"
                alt="main_logo"
              />
              <span class="ms-4 font-weight-bold text-white">
                FINOLEX EVOLVE
              </span>
            </a>
          </div>
          <hr class="horizontal light mt-0 mb-2" />
          <div
            class="collapse navbar-collapse  w-auto h-auto"
            id="sidenav-collapse-main"
          >
            <ul class="navbar-nav">
              <hr class="horizontal light mt-1" />
              <li class="nav-item">
                <h6 class="ps-4  ms-2 text-uppercase text-xs font-weight-bolder text-white">
                  Masters
                </h6>
              </li>
              <li class="nav-item">
                <a
                  data-bs-toggle="collapse"
                  href="#masters"
                  className={
                    splitLocationfirst === "masters"
                      ? "nav-link text-white active"
                      : "nav-link text-white"
                  }
                  aria-controls="masters"
                  role="button"
                  aria-expanded="false"
                >
                  <i class="material-icons-round opacity-10">dashboards</i>
                  <span class="nav-link-text ms-2 ps-1">Masters</span>
                </a>
                <div
                  className={
                    splitLocationfirst === "masters"
                      ? "collapse show"
                      : "collapse"
                  }
                  id="masters"
                >
                  <ul class="nav ">
                    <li class="nav-item">
                      <a
                        data-bs-toggle="collapse"
                        href="#geography"
                        className={
                          splitLocationsecond === "geography"
                            ? "nav-link text-white active"
                            : "nav-link text-white"
                        }
                        aria-controls="geography"
                        role="button"
                        aria-expanded="false"
                      >
                        <i class="material-icons-round opacity-10">public</i>

                        <span class="nav-link-text ms-2 ps-1">Geography</span>
                      </a>
                      <div
                        className={
                          splitLocationsecond === "geography"
                            ? "collapse show"
                            : "collapse"
                        }
                        id="geography"
                      >
                        <ul class="nav ">
                          <li class="nav-item ">
                            <a
                              className={
                                splitLocationlast === "countries"
                                  ? "nav-link text-white active"
                                  : "nav-link text-white"
                              }
                              href="/masters/geography/countries"
                            >
                              <span class="sidenav-mini-icon"> C </span>
                              <span class="sidenav-normal  ms-2  ps-1">
                                {" "}
                                Countries{" "}
                              </span>
                            </a>
                          </li>
                          <li class="nav-item ">
                            <a
                              className={
                                splitLocationlast === "states"
                                  ? "nav-link text-white active"
                                  : "nav-link text-white"
                              }
                              href="/masters/geography/states"
                            >
                              <span class="sidenav-mini-icon"> S </span>
                              <span class="sidenav-normal  ms-2  ps-1">
                                {" "}
                                States{" "}
                              </span>
                            </a>
                          </li>
                          <li class="nav-item ">
                            <a
                              className={
                                splitLocationlast === "districts"
                                  ? "nav-link text-white active"
                                  : "nav-link text-white"
                              }
                              href="/masters/geography/districts"
                            >
                              <span class="sidenav-mini-icon"> D </span>
                              <span class="sidenav-normal  ms-2  ps-1">
                                {" "}
                                Districts{" "}
                              </span>
                            </a>
                          </li>
                          <li class="nav-item ">
                            <a
                              className={
                                splitLocationlast === "citys"
                                  ? "nav-link text-white active"
                                  : "nav-link text-white"
                              }
                              href="/masters/geography/citys"
                            >
                              <span class="sidenav-mini-icon"> C </span>
                              <span class="sidenav-normal  ms-2  ps-1">
                                {" "}
                                Cities{" "}
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li class="nav-item">
                      <a
                        data-bs-toggle="collapse"
                        href="#rproducts"
                        className={
                          splitLocationsecond === "rproduct"
                            ? "nav-link text-white active"
                            : "nav-link text-white"
                        }
                        aria-controls="rproducts"
                        role="button"
                        aria-expanded="false"
                      >
                        <i class="material-icons-round opacity-10">
                          shopping_cart
                        </i>

                        <span class="nav-link-text ms-2 ps-1">
                          Redemption Products
                        </span>
                      </a>
                      <div
                        className={
                          splitLocationsecond === "rproduct"
                            ? "collapse show"
                            : "collapse"
                        }
                        id="rproducts"
                      >
                        <ul class="nav ">
                          <li class="nav-item ">
                            <a
                              className={
                                splitLocationlast === "rpCategorys"
                                  ? "nav-link text-white active"
                                  : "nav-link text-white"
                              }
                              href="/masters/rproduct/rpCategorys"
                            >
                              <span class="sidenav-mini-icon"> C </span>
                              <span class="sidenav-normal  ms-2  ps-1">
                                {" "}
                                Categories{" "}
                              </span>
                            </a>
                          </li>
                          <li class="nav-item ">
                            <a
                              className={
                                splitLocationlast === "rpProducts"
                                  ? "nav-link text-white active"
                                  : "nav-link text-white"
                              }
                              href="/masters/rproduct/rpProducts"
                            >
                              <span class="sidenav-mini-icon"> P </span>
                              <span class="sidenav-normal  ms-2  ps-1">
                                {" "}
                                Products{" "}
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item mt-3">
                <h6 class="ps-4  ms-2 text-uppercase text-xs font-weight-bolder text-white">
                  Organisation
                </h6>
              </li>
              <li class="nav-item">
                <a
                  href="/company"
                  className={
                    splitLocationlast === "company"
                      ? "nav-link text-white active"
                      : "nav-link text-white"
                  }
                  aria-controls="pagesExamples"
                  role="button"
                  aria-expanded="false"
                >
                  <i class="material-icons-round ">apartment</i>
                  <span class="nav-link-text ms-2 ps-1">Company</span>
                </a>
              </li>
              <li class="nav-item mt-3">
                <h6 class="ps-4  ms-2 text-uppercase text-xs font-weight-bolder text-white">
                  Settings
                </h6>
              </li>
              <li class="nav-item">
                <a
                  href="/configurations"
                  className={
                    splitLocationlast === "configurations"
                      ? "nav-link text-white active"
                      : "nav-link text-white"
                  }
                  aria-controls="pagesExamples"
                  role="button"
                  aria-expanded="false"
                >
                  <i class="material-icons-round ">settings</i>
                  <span class="nav-link-text ms-2 ps-1">Configurations</span>
                </a>
              </li>
              <li class="nav-item ">
                <a
                  className="nav-link text-white"
                  role="button"
                  aria-expanded="false"
                  onClick={this.handleLogout}
                >
                  <i class="material-icons-round ">logout</i>
                  <span class="nav-link-text ms-2 ps-1">Logout</span>
                </a>
              </li>
            </ul>
          </div>
        </aside>
      </>
    );
  }
}

export default Navbar;
