import React, { Component } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import CreateOrders from "./createOrders.component";
import Modal from 'react-modal';

class Orders extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
  }

  state = {
    name: "",
    sortBy: "",
    limit: 25,
    totalResults: 0,
    page: 1,
    members: [],
    search: "",
    create: 0,
    view: 0,
    verifyStatus: false,
    status: '',
    orderID: '',
    staus: "",
    remark: ""
  };

  componentDidMount() {
    const currentURL = window.location.pathname;
    const parts = currentURL.split("/");
    let programid = parts.pop();
    programid = parseInt(programid);
    const pathSegments = window.location.pathname.split('/'); // Split the URL path by '/'
    let compid = pathSegments[pathSegments.length - 2];
    const SALESRequest = async () => {

      try {
        const token = localStorage.getItem("token");
        let url = "";
        this.state.search !== ""
          ? (url =
            "redeem/catelog/orderAll?" +
            "name=" +
            this.state.search +
            "&mobile=" +
            this.state.search +
            "&id=" +
            this.state.search +
            "&sortBy=" +
            this.state.sortBy +
            "&limit=" +
            this.state.limit +
            "&page=" +
            this.state.page)
          : (url =
            "redeem/catelog/orderAll?sortBy=" +
            this.state.sortBy +

            "&limit=" +
            this.state.limit +
            "&page=" +
            this.state.page);
        const resp = await axios
          .get(Constants.api + url, {
            headers: {
              Authorization: `Bearer ${token}`, company: compid, program: programid, appVersion: Constants.av,
              platform: Constants.pt
            },

          })
          .then((resp) => {
            //  SALESs array

            if (resp.status == 201 && resp.data.result !== "error") {
              console.log(resp.data.output.results);
              this.setState({
                members: resp.data.output.results,
                totalResults: resp.data.output.totalResults,
                //   totalPages: resp.data.output.totalPages
              });
              console.log(this.state.members);
            } else if (resp.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
            }
          }).catch((err) => {
            // window.location = "/login"
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    SALESRequest();
  }
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount
    );
  };
  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ page: pageNumber }, this.componentDidMount);
  }

  render() {
    let verifyStatus = (id, status, remark) => {
      this.setState({ verifyStatus: !this.state.verifyStatus, orderID: id, status, remark })
    }
    const editItem = (id) => {
      try {
        const token = localStorage.getItem("token");
        const deletedata = new FormData();
        deletedata.append("id", id); //append the values with key, value pair

        axios
          .get(
            Constants.api + "redeem/catelog/orderAll/" + id,
            {
              headers: { Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("language") },
            },
            deletedata
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              this.setState({ create: 1, resp: resp });
            } else if (resp.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
            }
            //movie array
          }).catch((err) => {
            //  window.location = "/login"
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    const changeStatus = (id) => {
      try {
        const token = localStorage.getItem("token");
        const currentURL = window.location.pathname;

        const parts = currentURL.split("/");
        let programid = parts.pop();

        programid = parseInt(programid);
        axios
          .post(
            Constants.api + "redeem/catelog/update/order/status/" + id, {
            status: this.state.status,
            remark: this.state.remark
          },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                program: programid,
                "Accept-Language": localStorage.getItem("language"),
                appVersion: Constants.av,
                platform: Constants.pt
              },
            },

          )
          .then((resp) => {
            //  casetype array
            swal("Status Updated");
            this.setState({ verifyStatus: false, status: '', remark: '' }, this.componentDidMount());
            //movie array
          }).catch((err) => {
            //  window.location = "/login"
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    const viewItem = (id) => {
      try {
        const token = localStorage.getItem("token");
        const deletedata = new FormData();
        deletedata.append("id", id); //append the values with key, value pair

        axios
          .get(
            Constants.api + "redeem/catelog/orderAll/" + id,
            {
              headers: { Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("language") },
            },
            deletedata
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              this.setState({ view: 1, create: 1, resp: resp });
            } else if (resp.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
            }
            //movie array
          }).catch((err) => {
            //  window.location = "/login"
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    const deleteItem = (id) => {
      if (window.confirm("Are you sure?")) {
        try {
          const token = localStorage.getItem("token");
          const deletedata = new FormData();
          deletedata.append("id", id); //append the values with key, value pair

          axios
            .delete(
              Constants.api + "redeem/catelog/orderAll/" + id,
              {
                headers: { Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("language") },
              }
            )
            .then((resp) => {
              //  casetype array

              if (resp.status == 200) {
                this.componentDidMount();
              } else if (resp.status == 403) {
                localStorage.clear();
                window.location.href = "/login";
              } else {
              }
              //movie array
            });
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      }
    };

    const membersRender = this.state.members.map(function (item, i) {

      return (
        <>
          <tr>
            <td>
              <p>{item.id}</p>
            </td>
            <td>
              <p>{item.name}</p>
            </td>
            <td>
              <p>{item.mobile}</p>
            </td>
            <td>
              <p>{item.points}</p>
            </td>
            <td>
              <p>{item.status}</p>
            </td>
            <td>
              <p>{item.orderItems.map(function (j, i) {
                return (
                  <><img src={j.product.imageURL} width="20" />{j.product.name} * {j.quantity} ({j.points} Points)<br /></>
                )
              })}</p>
            </td>


            <td class="date_two">
              {item.createDateTime}
            </td>

            <td>
            {item.status != 'CANCELLED' && 
              <button class="btn btn-outline-primary btn-sm" onClick={() => verifyStatus(item.id, item.status, item.remark)}
              ><i class="material-icons text-lg">visibility</i> Change Status</button>
            }
              &nbsp;
            </td>
          </tr>
        </>
      );

    });

    return (
      <>
        {this.state.create == 0 ? (
          <>

            <main class="">
              <nav class="navbar navbar-main navbar-expand-lg position-sticky mt-4 top-1 px-0 mx-4 shadow-none border-radius-xl z-index-sticky" id="navbarBlur" data-scroll="true">
                <div class="container-fluid py-1 px-3">
                  <nav aria-label="breadcrumb">

                  </nav>
                  <div class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none ">
                    <a class="nav-link text-body p-0">
                      <div class="sidenav-toggler-inner">
                        <i class="sidenav-toggler-line"></i>
                        <i class="sidenav-toggler-line"></i>
                        <i class="sidenav-toggler-line"></i>
                      </div>
                    </a>
                  </div>
                  <div class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
                    <div class="ms-md-auto pe-md-3 d-flex align-items-center">
                      <div class="input-group input-group-outline">
                        <input type="text" class="form-control" placeholder="Search"
                          onChange={this.searchHandleChange} />
                      </div>
                    </div>
                    <ul class="navbar-nav  justify-content-end">
                      <li class="nav-item">
                        <div class="from_dropdown">
                          <label for="show">Show entries</label>
                          <select name="cars" id="show">
                            <option
                              value="25"
                              onClick={
                                (() => this.setState({ limit: 25 }),
                                  () => this.componentDidMount())
                              }
                            >
                              25
                            </option>
                            <option
                              value="50"
                              onClick={
                                (() => this.setState({ limit: 50 }),
                                  () => this.componentDidMount())
                              }
                            >
                              50
                            </option>
                            <option
                              value="75"
                              onClick={
                                (() => this.setState({ limit: 75 }),
                                  () => this.componentDidMount())
                              }
                            >
                              75
                            </option>
                            <option
                              value="100"
                              onClick={
                                (() => this.setState({ limit: 100 }),
                                  () => this.componentDidMount())
                              }
                            >
                              100
                            </option>
                          </select>
                        </div>
                      </li>

                    </ul>
                  </div>
                </div>
              </nav>
              <div class="container-fluid py-4">
                <div class="row">
                  <div class="col-12">
                    <div class="card">
                      <div class="card-header pb-0">
                        <div class="d-lg-flex">
                          <div>
                            <h5 class="mb-0"> Redeem Orders</h5>

                          </div>
                          {this.state.verifyStatus ? <>
                            <Modal
                              isOpen={this.state.verifyStatus}
                              onRequestClose={() => this.setState({ verifyStatus: false })}
                              className="custom-modal"
                              overlayClassName="custom-modal-overlay"
                            >
                              <button   onClick={() => this.setState({ verifyStatus: false })} type="button" class="btn-close btn bg-gradient-primary" data-bs-dismiss="modal" aria-label="Close">x</button>

                              <center>

                                <div class="row">
                                  <div class="col-sm-12">
                                    <div class=" mx-4 input-group-outline my-2">
                                      Status
                                    </div>

                                    <div class=" mx-4 input-group-outline my-2">
                                      <select value={this.state.status} name="status" class="form-control" onChange={(e) =>
                                        this.handleInputChange(e)
                                      } >
                                        <option value="">Select</option>
                                        <option value="ORDERED">ORDERED</option>
                                        <option value="INPROCESS">INPROCESS</option>
                                        <option value="PENDING">PENDING</option>
                                        <option value="CANCELLED">CANCELLED</option>
                                        <option value="DELIVERED">DELIVERED</option>

                                      </select>
                                    </div>
                                  </div>

                                </div>
                                <div class="row">
                                  <div class="col-sm-12">
                                    <div class=" mx-4 input-group-outline my-2">
                                      Remarks
                                    </div>
                                    <div class=" mx-4 input-group-outline my-2">
                                      <textarea class="form-control" name="remark" onChange={(e) =>
                                        this.handleInputChange(e)
                                      } >{this.state.remark}</textarea>
                                    </div>
                                  </div>

                                </div>

                                <div class="row">
                                  <div class="col-sm-12">
                                    <a href="#!" class="btn bg-gradient-primary mb-2" onClick={() => changeStatus(this.state.orderID)}
                                    >  Save Status</a>
                                  </div>
                                </div>
                              </center>

                            </Modal>
                          </>
                            : <></>
                          }
                        </div>
                      </div>
                      <div class="card-body px-0 pb-0">
                        <div class="table-responsive">
                          <table class="table table-flush" id="products-list">
                            <thead class="thead-light">
                              <tr>

                                <th >
                                  Order ID
                                </th>
                                <th >
                                  Member Name
                                </th>
                                <th >
                                  Member Mobile
                                </th>
                                <th >
                                  Points   </th>
                                <th >
                                  Status  </th>
                                <th >
                                  Order Items  </th>

                                <th >
                                  Created On
                                </th>

                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {membersRender}

                            </tbody>

                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </main>
            <div className="pagination-container">
              <Pagination
                activePage={this.state.page}
                itemsCountPerPage={this.state.limit}
                totalItemsCount={this.state.totalResults}
                pageRangeDisplayed={this.state.limit}
                onChange={this.handlePageChange.bind(this)}
              />
            </div>
          </>
        ) : this.state.resp ? (

          <CreateOrders
            resp={this.state.resp} />
        ) : (
          <CreateOrders
          />
        )}

      </>
    );
  }
}

export default Orders;
