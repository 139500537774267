import React, { Component } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import CreateMasterProgram from "./createmasterprogram.component";
import { matchPath } from 'react-router';
import CreateChildProgram from "./createchildprogram.component";

class ViewProgram extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    // this.getFoodRequest = this.getFoodRequest.bind(this);
    // this.handleClickActiveTab = this.handleClickActiveTab.bind(this);
  }

  state = {
    name: "",
    sortBy: "name",
    limit: 25,
    totalResults: 0,
    page: 1,
    program: {},
    search: "",
    create: 0,
    id: this.props.match.params.compid,
    childPrograms: []
  };

  componentDidMount() {
    const programsRequest = async () => {
      const token = localStorage.getItem("token");
      const currentURL = window.location.pathname;
      const parts = currentURL.split("/");
      let programid = parts.pop();
      programid = parseInt(programid);

      const pathSegments = window.location.pathname.split('/'); // Split the URL path by '/'
      let compid = pathSegments[pathSegments.length - 2]; // Get the last second segment

      let url = "program/master/" + this.state.id;
      try {

        const resp = await axios
          .get(Constants.api + url, {
            headers: { Authorization: `Bearer ${token}`, company: compid, program: programid, "Accept-Language": "en" },
          })
          .then((resp) => {
            //  casetype array
            // console.log("dsdsd");

            if (resp.status == 200) {
              // console.log(resp.data);
              this.setState({
                program: resp.data.output,
                childPrograms: resp.data.output.childProgramModels,
              });
            }  else if (resp.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
            }
            //movie array
          });

        //.catch((err) => {
        //   localStorage.clear();
        //   window.location = "/login"
        // });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    }
    programsRequest();


  }



  render() {

    const editChildProgram = (id) => {
      try {
        const token = localStorage.getItem("token");
        const deletedata = new FormData();
        deletedata.append("id", id); //append the values with key, value pair

        axios
          .get(
            Constants.api + "program/child/" + id,
            {
              headers: { Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("language") },
            },
            deletedata
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              this.setState({ create: 1, resp: resp });
            }  else if (resp.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
            }
            //movie array
          }).catch((err) => {
            //  window.location = "/login"
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    return (
      <>
        {this.state.create == 0 ? (
          <>

            <div class="main-content position-relative max-height-vh-100 h-100">

              <nav class="navbar navbar-main navbar-expand-lg position-sticky mt-4 top-1 px-0 mx-4 shadow-none border-radius-xl z-index-sticky" id="navbarBlur" data-scroll="true">
                <div class="container-fluid py-1 px-3">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
                      <li class="breadcrumb-item text-sm">
                        <a class="opacity-3 text-dark" href="/company">
                          Company
                        </a>
                      </li>
                      <li class="breadcrumb-item text-sm"><a class="opacity-5 text-dark" href="javascript:;">Programs</a></li>
                      <li class="breadcrumb-item text-sm text-dark active" aria-current="page">Master Program Overview</li>
                    </ol>
                    {/* <h6 class="font-weight-bolder mb-0">Profile Overview</h6> */}
                  </nav>
                  <div class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none ">
                    <a href="javascript:;" class="nav-link text-body p-0">
                      <div class="sidenav-toggler-inner">
                        <i class="sidenav-toggler-line"></i>
                        <i class="sidenav-toggler-line"></i>
                        <i class="sidenav-toggler-line"></i>
                      </div>
                    </a>
                  </div>

                </div>
              </nav>

              <div class="container-fluid px-2 px-md-4">
                <div class="page-header min-height-100 border-radius-xl mt-4">
                  <span class="mask  bg-gradient-primary  opacity-6"></span>
                </div>
                <div class="card card-body mx-3 mx-md-4 mt-n6">
                  <div class="row gx-4 mb-2">

                    <div class="col-auto my-auto">
                      <div class="h-100">
                        <h5 class="mb-1">
                          {this.state.program.name}
                        </h5>
                        <p class="mb-0 font-weight-normal text-sm">

                        </p>
                      </div>
                    </div>

                  </div>
                  <div class="row">
                    <div class="row mt-3">

                      <div class="col-12 col-md-12 mt-md-0 mt-4 position-relative">
                        <div class="card card-plain h-100">
                          <div class="card-header pb-0 p-3">
                            <div class="row">
                              <div class="col-md-8 d-flex align-items-center">
                                <h6 class="mb-0">Master Program Information</h6>
                              </div>
                              <div class="col-md-4 text-end">
                                <a href="javascript:;">
                                  <i class="fas fa-portal-enter text-secondary text-sm" data-bs-toggle="tooltip" data-bs-placement="top" title="Enter Program"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="card-body p-3">
                            <p class="text-sm">
                              {this.state.program ? this.state.program.description : ''}
                            </p>
                            <hr class="horizontal gray-light my-4" />

                          </div>
                        </div>
                        <hr class="vertical dark" />
                      </div>

                    </div>


                    <div class="container-fluid py-4">
                      <section class="py-3">
                        <div class="row mb-4 mb-md-0">
                          <div class="col-md-8 me-auto my-auto text-left">
                            <h5>Child Programs</h5>
                          </div>
                          <div class="col-lg-4 col-md-12 my-auto text-end" >
                            <a href="#" class="btn bg-gradient-primary mb-2" onClick={() => this.setState({ create: 1 })}
                            > <i class="material-icons text-white position-relative text-md pe-2">add</i> Add New</a>
                          </div>
                        </div>
                        <div class="row">

                          {this.state.childPrograms.map(function (item, i) {
                            return (
                              <>
                                <div class="col-xl-3 col-md-6 mb-xl-0 mb-4">
                                  <div class="card card-blog card-plain">

                                    <div class="card-body p-3">
                                      <h5>
                                        {item.name}
                                      </h5>
                                      <div class="avatar-group">

                                      </div>

                                      <p class="mb-4 text-sm">
                                        {item.description}
                                      </p>
                                      <div class="d-flex align-items-center justify-content-between">
                                        <a href="javascript:void(0)" onClick={() => editChildProgram(item.id)}>
                                          <button type="button" class="btn btn-outline-primary btn-sm mb-0">Enter Program</button>
                                        </a>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                          })}
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>




          </>
        ) : this.state.resp ? (
          <CreateChildProgram resp={this.state.resp} />
        ) : (
          <CreateChildProgram />
        )
        }
      </>
    );
  }
}

export default ViewProgram;
