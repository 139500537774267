import React, { Component, useEffect, useState } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import Switch from "react-switch";
import SearchInput from "react-search-input";
import ReactDragListView from "react-drag-listview";

class CreateProgramComms extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      name: this.props.resp ? this.props.resp.data.output.name : "",
      eFrom: this.props.resp ? this.props.resp.data.output.eFrom : "",
      eSubject: this.props.resp ? this.props.resp.data.output.eSubject : "",
      eTo: this.props.resp ? this.props.resp.data.output.eTo : "",
      eCC: this.props.resp ? this.props.resp.data.output.eCC : "",
      eBCC: this.props.resp ? this.props.resp.data.output.eBCC : "",
      eTemplate: this.props.resp ? this.props.resp.data.output.eTemplate : "",
      sTemplate: this.props.resp ? this.props.resp.data.output.sTemplate : "",
      stemplateId: this.props.resp ? this.props.resp.data.output.stemplateId : "",

      nTemplate: this.props.resp ? this.props.resp.data.output.nTemplate : "",
      wTemplate: this.props.resp ? this.props.resp.data.output.wTemplate : "",
      eActive: this.props.resp ? this.props.resp.data.output.eActive : false,
      sActive: this.props.resp ? this.props.resp.data.output.sActive : false,
      nActive: this.props.resp ? this.props.resp.data.output.nActive : false,
      wActive: this.props.resp ? this.props.resp.data.output.wActive : false,
      contentId: this.props.resp ? this.props.resp.data.output.contentId : ""
    };
    this.emailActiveHandleChange = this.emailActiveHandleChange.bind(this);
    this.smsActiveHandleChange = this.smsActiveHandleChange.bind(this);
    this.waActiveHandleChange = this.waActiveHandleChange.bind(this);
    this.notifyActiveHandleChange = this.notifyActiveHandleChange.bind(this);
  }

  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount()
    );
  };

  nameHandleChange = (event) => {
    this.setState({ name: event.target.value });
  };
  emailBccHandleChange = (event) => {
    this.setState({ emailBcc: event.target.value });
  };
  emailCcHandleChange = (event) => {
    this.setState({ emailCc: event.target.value });
  };
  emailBodyHandleChange = (event) => {
    this.setState({ emailBody: event.target.value });
  };
  emailActiveHandleChange = (checked) => {
    this.setState({ eActive: checked });
  };
  smsBodyHandleChange = (event) => {
    this.setState({ smsBody: event.target.value });
  };
  smsActiveHandleChange = (checked) => {
    this.setState({ sActive: checked });
  };
  smsBodyHandleChange = (event) => {
    this.setState({ smsBody: event.target.value });
  };
  waActiveHandleChange = (checked) => {
    this.setState({ wActive: checked });
  };
  waBodyHandleChange = (event) => {
    this.setState({ waBody: event.target.value });
  };
  notifyActiveHandleChange = (checked) => {
    this.setState({ nActive: checked });
  };
  notifyBodyHandleChange = (event) => {
    this.setState({ notifyBody: event.target.value });
  };
  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      const token = localStorage.getItem("token");
      const currentURL = window.location.pathname;
      const parts = currentURL.split("/");
      let programid = parts.pop();
      programid = parseInt(programid);
      const pathSegments = window.location.pathname.split('/'); // Split the URL path by '/'
      let compid = pathSegments[pathSegments.length - 2];
      if (this.props.resp) {
        axios
          .patch(
            Constants.api + "program/comms/" + this.props.resp.data.output.id,
            {
              name: this.state.name,
              eFrom: this.state.eFrom,
              eSubject: this.state.eSubject,
              eTo: this.state.eTo,
              eCC: this.state.eCC,
              eBCC: this.state.eBCC,
              eTemplate: this.state.eTemplate,
              stemplateId: this.state.stemplateId,
              sTemplate: this.state.sTemplate,
              nTemplate: this.state.nTemplate,
              wTemplate: this.state.wTemplate,
              eActive: this.state.eActive,
              sActive: this.state.sActive,
              nActive: this.state.nActive,
              wActive: this.state.wActive,
              contentId: this.state.contentId
            },
            {
              headers: {
                Authorization: `Bearer ${token}`, company: compid, program: programid, appVersion: Constants.av,
                platform: Constants.pt
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              swal("Template Updated");
              // this.setState({ create: 0 });
              this.onCancelTrigger(0);
            }  else if (res.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          })
          .catch((err) => {
            //localStorage.clear();
            //    window.location = "/login"
          });;
      } else {
        axios
          .post(
            Constants.api + "program/comms",
            {
              name: this.state.name,
              eFrom: this.state.eFrom,
              eSubject: this.state.eSubject,
              eTo: this.state.eTo,
              eCC: this.state.eCC,
              eBCC: this.state.eBCC,
              eTemplate: this.state.eTemplate,
              sTemplate: this.state.sTemplate,
              stemplateId: this.state.stemplateId,

              nTemplate: this.state.nTemplate,
              wTemplate: this.state.wTemplate,
              eActive: this.state.eActive,
              sActive: this.state.sActive,
              isnActive: this.state.isnActive,
              wActive: this.state.wActive,
              contentId: this.state.contentId
            },
            {
              headers: {
                Authorization: `Bearer ${token}`, company: compid, program: programid, appVersion: Constants.av,
                platform: Constants.pt
              },
            }
          )
          .then((res) => {
            if (res.status == 201) {
              swal("Template Added");
              this.onCancelTrigger(0);
              // this.setState({ createTemplate: 0 })
            }  else if (res.status == 403) {
              localStorage.clear();
              window.location.href = "/login";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          })
          .catch((err) => {
            //localStorage.clear();
            //    window.location = "/login"
          });;
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };


  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  onCancelTrigger = (event) => {
    this.props.setViewMode(event);
    event.preventDefault();
  }
  //this.onCancelTrigger(0)


  render() {
    if (this.state.create == 0) {
      window.location.reload()
    }

    let search = this.state.search;
    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const category = [...that.state.category];
        const item = category.splice(fromIndex, 1)[0];
        category.splice(toIndex, 0, item);
        that.setState({ category });
      },
      nodeSelector: "li",
      handleSelector: ".move",
    };
    return (
      <>
        <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
          <nav class="navbar navbar-main navbar-expand-lg position-sticky mt-4 top-1 px-0 mx-4 shadow-none border-radius-xl z-index-sticky" id="navbarBlur" data-scroll="true">
            <div class="container-fluid py-1 px-3">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">

                  <li class="breadcrumb-item text-sm"><a class="opacity-5 text-dark" href="javascript:;"> Program Communication</a></li>
                  <li class="breadcrumb-item text-sm text-dark active" aria-current="page">Add New</li>
                </ol>
              </nav>
              <div class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none ">
                <a href="javascript:;" class="nav-link text-body p-0">
                  <div class="sidenav-toggler-inner">
                    <i class="sidenav-toggler-line"></i>
                    <i class="sidenav-toggler-line"></i>
                    <i class="sidenav-toggler-line"></i>
                  </div>
                </a>
              </div>

            </div>
          </nav>
          <div class="card mt-4" id="basic-info">
            <div class="card-header">
              <div class="col-md-8">

              </div>
              <h5>Program Communication</h5>
            </div>
            <form onSubmit={this.handleFormSubmit}>
              <div class="card-body pt-0">

                <div class="row">
                  <div class="col-md-12">
                    <label class="form-label mt-4">Name</label>
                    <input class="form-control" id="choices-skills" type="text" placeholder="" name="name" value={this.state.name} onChange={this.nameHandleChange} />
                    {this.validator.message(
                      "Template Name",
                      this.state.name,
                      "required"
                    )}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <label class="form-label mt-4">Email Active</label>

                    <Switch class="form-control" onChange={this.emailActiveHandleChange} checked={this.state.eActive} />
                  </div>
                </div>
                {this.state.eActive ?
                  <>
                    <div class="row">
                      <div class="col-md-6">
                        <label class="form-label mt-4">Email From</label>
                        <input class="form-control" id="choices-skills" type="text" placeholder="" name="eFrom" value={this.state.eFrom} onChange={this.handleInputChange} />
                        {this.validator.message(
                          "emailFrom",
                          this.state.eFrom,
                          "required"
                        )}
                      </div>
                      <div class="col-md-6">
                        <label class="form-label mt-4">Email Subject</label>
                        <input class="form-control" id="choices-skills" type="text" placeholder="" name="eSubject" value={this.state.eSubject} onChange={this.handleInputChange} />
                        {this.validator.message(
                          "emailSubject",
                          this.state.eSubject,
                          "required"
                        )}
                      </div>

                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <label class="form-label mt-4">Email CC</label>
                        <input class="form-control" id="choices-skills" type="text" placeholder="" name="eCC" value={this.state.eCC} onChange={this.handleInputChange} />

                      </div>
                      <div class="col-md-6">
                        <label class="form-label mt-4">Email BCC</label>
                        <input class="form-control" id="choices-skills" type="text" placeholder="" name="eBCC" value={this.state.eBCC} onChange={this.emailBccHandleChange} />

                      </div>

                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <label class="form-label mt-4">Email Body</label>
                        <textarea class="form-control" id="choices-skills" type="text" placeholder="" name="eTemplate" onChange={this.handleInputChange}>{this.state.eTemplate}</textarea>
                        {this.validator.message(
                          "emailBody",
                          this.state.eTemplate,
                          "required"
                        )}
                      </div>
                    </div> </> : ''}
                <div class="row">
                  <div class="col-md-6">
                    <label class="form-label mt-4">SMS Active</label>

                    <Switch class="form-control" onChange={this.smsActiveHandleChange} checked={this.state.sActive} />
                  </div>
                </div>
                {this.state.sActive ?
                  <>

                    <div class="row">
                      <div class="col-md-12">
                        <label class="form-label mt-4">SMS Body</label>
                        <textarea class="form-control" id="choices-skills" type="text" placeholder="" name="sTemplate" onChange={this.handleInputChange}>{this.state.sTemplate}</textarea>
                        {this.validator.message(
                          "SMS Body",
                          this.state.sTemplate,
                          "required"
                        )}
                      </div>
                      <div class="col-md-12">
                        <label class="form-label mt-4">Template ID</label>
                        <input class="form-control" id="choices-skills" type="text" placeholder="" name="stemplateId" value={this.state.stemplateId} onChange={this.handleInputChange} />
                        {this.validator.message(
                          "SMS templateId",
                          this.state.stemplateId,
                          "required"
                        )}
                      </div>
                    </div> </> : ''}
                <div class="row">
                  <div class="col-md-6">
                    <label class="form-label mt-4">Whatsapp Active</label>

                    <Switch class="form-control" onChange={this.waActiveHandleChange} checked={this.state.wActive} />
                  </div>
                </div>
                {this.state.wActive ?
                  <>

                    <div class="row">
                      <div class="col-md-12">
                        <label class="form-label mt-4">Whatsapp Body</label>
                        <textarea class="form-control" id="choices-skills" type="text" placeholder="" name="wTemplate" onChange={this.handleInputChange}>{this.state.wTemplate}</textarea>
                        {this.validator.message(
                          "Whatsapp Body",
                          this.state.wTemplate,
                          "required"
                        )}
                      </div>
                    </div> </> : ''}
                <div class="row">
                  <div class="col-md-6">
                    <label class="form-label mt-4">Notification Active</label>

                    <Switch class="form-control" onChange={this.notifyActiveHandleChange} checked={this.state.isnActive} />
                  </div>
                </div>
                {this.state.isnActive ?
                  <>

                    <div class="row">
                      <div class="col-md-12">
                        <label class="form-label mt-4">Notification Body</label>
                        <textarea class="form-control" id="choices-skills" type="text" placeholder="" name="nTemplate" onChange={this.handleInputChange}>{this.state.nTemplate}</textarea>
                        {this.validator.message(
                          "Notify Body",
                          this.state.nTemplate,
                          "required"
                        )}
                      </div>
                    </div> </> : ''}
                <div class=" mt-4" id="delete">
                  <div class="card-body">
                    <div class="d-flex align-items-center mb-sm-0 mb-4">
                      <div class="w-50">
                        {/* <p>Save State</p> */}
                        {/* <p class="text-sm mb-0">Once you delete your account, there is no going back. Please be certain.</p> */}
                      </div>
                      <div class="w-50 text-end">
                        <button class="btn bg-gradient-success mb-0 ms-2" type="submit" name="button" >Save </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

        </main >

      </>
    );
  }

}

export default CreateProgramComms;
